import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaRegEye} from 'react-icons/fa';
import './definiciones.css';

import logo_mam from './mam.png';
import logo_masw from './masw.png';
import logo_microtremor from './microtremor.png';
import logo_perforacion from './perforacion.png';


export class Definiciones extends Component{
    constructor(props) {
        super(props);
        this.state = {
            expand_cuadro_1: 'expandido',
            expand_cuadro_2: 'expandido',
            expand_cuadro_3: 'expandido',
            expand_cuadro_4: 'expandido',
        }
        
    }
    contrae_expande = () =>{
        if (this.state.expand_cuadro_1=='contraido') {
            this.setState({
                expand_cuadro_1: 'expandido',
            });
        }
        else{
            this.setState({
                expand_cuadro_1: 'contraido',
            });
        }
        
    }
    contrae_expande1 = () =>{
        if (this.state.expand_cuadro_2=='contraido') {
            this.setState({
                expand_cuadro_2: 'expandido',
            });
        }
        else{
            this.setState({
                expand_cuadro_2: 'contraido',
            });
        }
        
    }
    contrae_expande2 = () =>{
        if (this.state.expand_cuadro_3=='contraido') {
            this.setState({
                expand_cuadro_3: 'expandido',
            });
        }
        else{
            this.setState({
                expand_cuadro_3: 'contraido',
            });
        }
        
    }
    contrae_expande3 = () =>{
        if (this.state.expand_cuadro_4=='contraido') {
            this.setState({
                expand_cuadro_4: 'expandido',
            });
        }
        else{
            this.setState({
                expand_cuadro_4: 'contraido',
            });
        }
        
    }
    render(){
        return(
            <Container className="contenedor_definiciones">
                <Row>
                    <Col sm={12} className="definiciones">
                        <div className="definicion">
                            <div className="seccion_titulo">
                                <p className="titulo_definicion">MASW <span onClick={this.contrae_expande} className="boton_control" title="Expande para ver la definición"><FaRegEye/></span></p>
                            </div>
                            <div className={this.state.expand_cuadro_1}>
                                <div className="contenido_definicion">
                                    <p className="descripcion_definicion">
                                        Los sondajes MASW o Análisis de Ondas Superficiales en Arreglo Multicanal es un método de exploración geofísica que permite determinar la estratigrafía del subsuelo bajo un punto en forma indirecta, basándose en el cambio de las propiedades dinámicas de los materiales que la conforman. Este método consiste en la interpretación de las ondas superficiales (ondas Rayleigh) de un registro en arreglo multicanal, generadas por una fuente de energía impulsiva en puntos localizados a distancias predeterminadas a lo largo de un eje sobre la superficie del terreno, obteniéndose el perfil de velocidades de ondas de corte (Vs) para el punto central de dicha línea.
                                    </p>
                                    <img className="imagen_definicion" src={logo_masw}></img>
                                </div>
                            </div>
                        </div>
                        <div className="definicion">
                            <div className="seccion_titulo">
                                <p className="titulo_definicion">MAM <span onClick={this.contrae_expande1} className="boton_control" title="Expande para ver la definición"><FaRegEye/></span></p>
                            </div>
                            <div className={this.state.expand_cuadro_2}>
                                <div className="contenido_definicion">
                                    <p className="descripcion_definicion">
                                        Análisis de Arreglo de Microtremores consiste en analizar las vibraciones ambientales o microtremores, éstos son registrados por múltiples receptores (geófonos) y sus velocidades de fase son analizadas. Aki (1957) analizó los microtremores como ondas superficiales con la teoría de correlación auto espacial (SPAC Spatial Auto Correlation), la cual permite calcular la velocidad de fase verdadera de las velocidades aparentes (Hayashi, 2008) obteniéndose la curva de dispersión; finalmente mediante un proceso de inversión se obtiene el perfil de velocidad de onda S (Vs).
                                    </p>
                                    <img className="imagen_definicion" src={logo_mam}></img>
                                </div>
                            </div>
                        </div>
                        <div className="definicion">
                            <div className="seccion_titulo">
                                <p className="titulo_definicion">MICROTREMOR <span onClick={this.contrae_expande2} className="boton_control" title="Expande para ver la definición"><FaRegEye/></span></p>
                            </div>
                            <div className={this.state.expand_cuadro_3}>
                                <div className="contenido_definicion">
                                    <p className="descripcion_definicion">
                                        La superficie de la Tierra se ve constantemente afectada por pequeñas vibraciones caracterizadas por amplitudes del orden de 0.1 – 10 um, por debajo de la detección humana. Este tipo de movimiento se llama ruido sísmico, microtremores o vibraciones ambientales. La forma más sencilla de obtener alguna información del ruido ambiental consiste en el empleo del cociente entre las amplitudes espectrales de las componentes horizontal y vertical del movimiento. Esta técnica, conocida como Técnica de Nakamura, H/V o HVSR (Horizontal-to-Vertical Spectral Ratio) ha sido empleada extensivamente en las últimas décadas, dada su economía.
                                    </p>
                                    <img className="imagen_definicion" src={logo_microtremor}></img>
                                </div>
                            </div>
                        </div>
                        <div className="definicion">
                            <div className="seccion_titulo">
                                <p className="titulo_definicion">PERFORACIÓN <span onClick={this.contrae_expande3} className="boton_control" title="Expande para ver la definición"><FaRegEye/></span></p>
                            </div>
                            <div className={this.state.expand_cuadro_4}>
                                <div className="contenido_definicion">
                                    <p className="descripcion_definicion">
                                        Una perforación geotécnica es una técnica empleada que tiene como objetivo de establecer un perfil del suelo del punto de exploración; opcionalmente, se realizan ensayos para determinar (de forma directa o indirecta) las propiedades físicas y químicas asociadas a cada estrato del perfil. En este sentido, las técnicas de prospección mediante técnica de perforación obtiene muestras totalmente alterada o mezclada). 
                                    </p>
                                    <img className="imagen_definicion" src={logo_perforacion}></img>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            
        )
    }
}

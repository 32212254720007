import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import './seccion_acceso.css';
import Titulo from './etiqueta_titulo';
//externals
import ReactDOM from 'react-dom';
import React from 'react';
//Stilos
import catalogo from './catalogo.png';
import catalogo2 from './portalwebSencico.png';
import Seccion from './seccion';
export default class DirectAccess extends React.Component {
    render(){
        return(
            <Container className="accesos_secciones">
                <Row>
                    {/*<Titulo class_titulo="titulo" titulo="Contenido del Portal"/>*/}
                    <Seccion 
                        class_col="seccion"
                        class_contenido="contenido"
                        class_descripcion="Descripcion"
                        titulo="Catálogo"
                        src_image={catalogo2}
                        class_image="image-catalogo"
                        parrafo="Portal georeferenciado de ensayos geofísicos realizados a nivel nacional, iniciativa de rtecopilación de diversas fuentes por Sencico"
                        class_direccion="Direccion"
                        redirige="./CatalogoWeb"
                        class_nav="naveg"
                        nombre_button="Empezar"
                    />
                    <Seccion 
                        class_col="seccion"
                        class_contenido="contenido"
                        class_descripcion="Descripcion"
                        titulo="Documentación"
                        src_image="./files.png"
                        class_image="image-catalogo"
                        parrafo="Documentación detallada referente a los ensayos geofísicos realizados"
                        class_direccion="Direccion"
                        redirige="./Documentacion"
                        class_nav="naveg"
                        nombre_button="Revisar"
                    />
                    <Seccion 
                        class_col="seccion"
                        class_contenido="contenido"
                        class_descripcion="Descripcion"
                        titulo="Instituciones"
                        src_image="./instituciones.jpg"
                        class_image="image-catalogo"
                        parrafo="Relación de instituciones que aportan información de ensayos geofísicos desarrollados"
                        class_direccion="Direccion"
                        redirige="./Instituciones"
                        class_nav="naveg"
                        nombre_button="Ver"
                    />
                    <Seccion 
                        class_col="seccion"
                        class_contenido="contenido"
                        class_descripcion="Descripcion"
                        titulo="Inicio"
                        src_image="./sencicoedificio.jpg"
                        class_image="image-catalogo"
                        parrafo="Visualiza la portada del catalogo de ensayos geofísicos realizados en el Perú"
                        class_direccion="Direccion"
                        redirige="./"
                        class_nav="naveg"
                        nombre_button="Ir"
                    />
                </Row>
            </Container>
        );
    }
}
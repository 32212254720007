import React from 'react';
import logo from './logo.svg';
import './App.css';
//import Button from 'react-bootstrap/Button';
import {Home} from './components/Home/Home';
import {Documentacion} from './components/Documentacion/Documentacion';
import {Catalogo} from './components/Catalogo/Catalogo';
import {Catalogo2} from './components/Catalogo2/Catalogo2';
import {DetalleCatalogo} from './components/Catalogo/DetalleCatalogo';
import {Instituciones} from './components/Instituciones/Instituciones';
import {Busqueda} from './components/Busqueda/Busqueda';
import {Definiciones} from './components/Definiciones/Definiciones';
import {Manual} from './components/Manual/Manual';
import {Referencias} from './components/Referencias/Referencias';
//import {Navigation} from './components/Navigation';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
//import FullPageIntroWithFixedTransparentNavbar from './components/Navbar';
import FullPageIntroWithFixedTransparentNavbar from './components/Navbar2';
//import SideNavPage from './components/SideNav';

function App() {
  return (
    <BrowserRouter>
    <div className="">
      <div className="fixed-top container-fluid navnav">
        {/*<img src={logo} className="App-logo" alt="logo" />*/}
        <FullPageIntroWithFixedTransparentNavbar/>
        {/*<Navigation/> */}
      </div>
    </div>  
    <div className="mtop  window-container">
    <Switch className="">
      <Route path='/' component={Home} exact/>
      <Route path='/Documentacion' component={Documentacion} exact/>
      <Route path='/CatalogoMovil' component={Catalogo} exact/>
      <Route path='/CatalogoWeb' component={Catalogo2} exact/>
      <Route path='/Instituciones' component={Instituciones} exact/>
      <Route path='/Referencias' component={Referencias} exact/>
      <Route path='/Manual' component={Manual} exact/>
      <Route path='/Busqueda' component={Busqueda} exact/>
      <Route path='/Definiciones' component={Definiciones} exact/>
      <Route path='/Catalogo/:tipo_ensayo/:id'component={DetalleCatalogo}/>
    </Switch>
    </div>
    
    </BrowserRouter>
  );
}

export default App;

import React, {Component} from 'react';
import Mapaf from './mapa2';


export class Catalogo2 extends Component{
    render(){
        return(
            <div className="">
                <Mapaf/>
                
            </div>
        )
    }
}
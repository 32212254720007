import React, {Component} from 'react';
import { MDBIcon } from 'mdbreact';
import BanerPrincipal from './banner';
import Titulo from './etiqueta_titulo';
import Descripcion from './etiqueta_descripcion';
import DirectAccess from './secciones_acceso';
import MapContainer from './Map';
import {Modal, Button} from 'react-bootstrap';

export class Home extends Component{
    constructor(props) {
        super(props);
        this.state = {
            toggle_modal: true,
        }
    }
    close_modal = () => {
        this.setState({toggle_modal:false})
    }

    render(){
        return(
            <div className="part-ini">
                {/* <h4>Bienvenido a la Plataforma Virtual de Visualización Sistematizada de Perfiles de Suelo.</h4> */}
                <Titulo/>
                <BanerPrincipal/>
                <Descripcion/>
                <DirectAccess/>
                {/* <MapContainer/>
                <div>
                    <GoogleApiWrapper alt="logo2"/>
                </div>
                <div>
                    <br></br> 
                    <img src="https://mdbootstrap.com/img/Photos/Others/img%20(40).jpg" className="img_inicio"  alt="logo"/>
                    <h3>Bienvenido a la pagina de Inicio</h3>
                    <h3>Bienvenido a la pagina de Inicio</h3>
                    <h3>Bienvenido a la pagina de Inicio</h3>
                    <h3>Bienvenido a la pagina de Inicio</h3>
                    <h3>Bienvenido a la pagina de Inicio</h3>
                    <h3>Bienvenido a la pagina de Inicio</h3>
                    <MDBIcon fab icon="adn" />
                </div>
                <BanerPrincipal/> */}
                <Modal show={this.state.toggle_modal} onHide={this.close_modal} size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                    <Modal.Header className="Header_Modal" closeButton variant="primary">
                    <Modal.Title className="Title_Modal" variant="primary">Bienvenidos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="ini_presentacion">
                        En esta plataforma encontrarás  los perfiles de suelo elaborados a partir de  estudios geofísicos y geotécnicos, desarrollados por diversas instituciones públicas y privadas. Esta base de datos inicial, se irá incrementándose  conforme se desarrollen estudios similares  en el país. 
                        Podrás encontrar  información de perfiles  de suelo, del entorno del punto de interés.
                        </p>
                        <br/>
                    </Modal.Body>
                    <Modal.Footer className="Footer_Modal">
                    <Button className="Close_Modal" variant="danger" onClick={this.close_modal}>
                        Cerrar
                    </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }
}

//export default Home;
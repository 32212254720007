import React, {Component} from 'react';
import Mapaf from './mapa';


export class Catalogo extends Component{
    render(){
        return(
            <div className="">
                <Mapaf/>
                {/* <SideBar className="Sidebar"/> */}
                
            </div>
        )
    }
}
import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './manual.css';
import ContenidoManual from './contenido';
export class Manual extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            state_boton_1: 'Botones_Manuales',
            state_boton_2: 'Botones_Manuales_Inactive',
            state_boton_3: 'Botones_Manuales_Inactive',
            state_boton_4: 'Botones_Manuales_Inactive',
            state_boton_5: 'Botones_Manuales_Inactive',
            state_boton_6: 'Botones_Manuales_Inactive',
            marker_boton_1: 'activo',
            marker_boton_2: 'inactivo',
            marker_boton_3: 'inactivo',
            marker_boton_4: 'inactivo',
            marker_boton_5: 'inactivo',
            marker_boton_6: 'inactivo',
            state_manual_1: 'pdf_activo',
            state_manual_2: 'pdf_inactivo',
            state_manual_3: 'pdf_inactivo',
            state_manual_4: 'pdf_inactivo',
            state_manual_5: 'pdf_inactivo',
            state_manual_6: 'pdf_inactivo',
        }
        this.cambiaManual = this.cambiaManual.bind(this);
    }
    cambiaManual(e){
        console.log(e.target.value);
        if(e.target.value == '1'){
          this.setState({
            state_boton_1: 'Botones_Manuales',
            state_boton_2: 'Botones_Manuales_Inactive',
            state_boton_3: 'Botones_Manuales_Inactive',
            state_boton_4: 'Botones_Manuales_Inactive',
            state_boton_5: 'Botones_Manuales_Inactive',
            state_boton_6: 'Botones_Manuales_Inactive',
            marker_boton_1: 'activo',
            marker_boton_2: 'inactivo',
            marker_boton_3: 'inactivo',
            marker_boton_4: 'inactivo',
            marker_boton_5: 'inactivo',
            marker_boton_6: 'inactivo',
            state_manual_1: 'pdf_activo',
            state_manual_2: 'pdf_inactivo',
            state_manual_3: 'pdf_inactivo',
            state_manual_4: 'pdf_inactivo',
            state_manual_5: 'pdf_inactivo',
            state_manual_6: 'pdf_inactivo',
          })
        }
        if(e.target.value == '2'){
            this.setState({
              state_boton_1: 'Botones_Manuales_Inactive',
              state_boton_2: 'Botones_Manuales',
              state_boton_3: 'Botones_Manuales_Inactive',
              state_boton_4: 'Botones_Manuales_Inactive',
              state_boton_5: 'Botones_Manuales_Inactive',
              state_boton_6: 'Botones_Manuales_Inactive',
              marker_boton_1: 'inactivo',
              marker_boton_2: 'activo',
              marker_boton_3: 'inactivo',
              marker_boton_4: 'inactivo',
              marker_boton_5: 'inactivo',
              marker_boton_6: 'inactivo',
              state_manual_1: 'pdf_inactivo',
              state_manual_2: 'pdf_activo',
              state_manual_3: 'pdf_inactivo',
              state_manual_4: 'pdf_inactivo',
              state_manual_5: 'pdf_inactivo',
              state_manual_6: 'pdf_inactivo',
            })
          }
          if(e.target.value == '3'){
            this.setState({
              state_boton_1: 'Botones_Manuales_Inactive',
              state_boton_2: 'Botones_Manuales_Inactive',
              state_boton_3: 'Botones_Manuales',
              state_boton_4: 'Botones_Manuales_Inactive',
              state_boton_5: 'Botones_Manuales_Inactive',
              state_boton_6: 'Botones_Manuales_Inactive',
              marker_boton_1: 'inactivo',
              marker_boton_2: 'inactivo',
              marker_boton_3: 'activo',
              marker_boton_4: 'inactivo',
              marker_boton_5: 'inactivo',
              marker_boton_6: 'inactivo',
              state_manual_1: 'pdf_inactivo',
              state_manual_2: 'pdf_inactivo',
              state_manual_3: 'pdf_activo',
              state_manual_4: 'pdf_inactivo',
              state_manual_5: 'pdf_inactivo',
              state_manual_6: 'pdf_inactivo',
            })
          }
          if(e.target.value == '4'){
            this.setState({
              state_boton_1: 'Botones_Manuales_Inactive',
              state_boton_2: 'Botones_Manuales_Inactive',
              state_boton_3: 'Botones_Manuales_Inactive',
              state_boton_4: 'Botones_Manuales',
              state_boton_5: 'Botones_Manuales_Inactive',
              state_boton_6: 'Botones_Manuales_Inactive',
              marker_boton_1: 'inactivo',
              marker_boton_2: 'inactivo',
              marker_boton_3: 'inactivo',
              marker_boton_4: 'activo',
              marker_boton_5: 'inactivo',
              marker_boton_6: 'inactivo',
              state_manual_1: 'pdf_inactivo',
              state_manual_2: 'pdf_inactivo',
              state_manual_3: 'pdf_inactivo',
              state_manual_4: 'pdf_activo',
              state_manual_5: 'pdf_inactivo',
              state_manual_6: 'pdf_inactivo',
            })
          }
          if(e.target.value == '5'){
            this.setState({
              state_boton_1: 'Botones_Manuales_Inactive',
              state_boton_2: 'Botones_Manuales_Inactive',
              state_boton_3: 'Botones_Manuales_Inactive',
              state_boton_4: 'Botones_Manuales_Inactive',
              state_boton_5: 'Botones_Manuales',
              state_boton_6: 'Botones_Manuales_Inactive',
              marker_boton_1: 'inactivo',
              marker_boton_2: 'inactivo',
              marker_boton_3: 'inactivo',
              marker_boton_4: 'inactivo',
              marker_boton_5: 'activo',
              marker_boton_6: 'inactivo',
              state_manual_1: 'pdf_inactivo',
              state_manual_2: 'pdf_inactivo',
              state_manual_3: 'pdf_inactivo',
              state_manual_4: 'pdf_inactivo',
              state_manual_5: 'pdf_activo',
              state_manual_6: 'pdf_inactivo',
            })
          }
          if(e.target.value == '6'){
            this.setState({
              state_boton_1: 'Botones_Manuales_Inactive',
              state_boton_2: 'Botones_Manuales_Inactive',
              state_boton_3: 'Botones_Manuales_Inactive',
              state_boton_4: 'Botones_Manuales_Inactive',
              state_boton_5: 'Botones_Manuales_Inactive',
              state_boton_6: 'Botones_Manuales',
              marker_boton_1: 'inactivo',
              marker_boton_2: 'inactivo',
              marker_boton_3: 'inactivo',
              marker_boton_4: 'inactivo',
              marker_boton_5: 'inactivo',
              marker_boton_6: 'activo',
              state_manual_1: 'pdf_inactivo',
              state_manual_2: 'pdf_inactivo',
              state_manual_3: 'pdf_inactivo',
              state_manual_4: 'pdf_inactivo',
              state_manual_5: 'pdf_inactivo',
              state_manual_6: 'pdf_activo',
            })
          }
        
    }
    render(){
        return(
            <Container fluid className="contenedor_manual">
                <Row>
                    <Col sm={3} className="Controles_Manuales">
                        <button value="1" onClick={this.cambiaManual} className={this.state.state_boton_1}>Manual de uso de la Plataforma <span className={this.state.marker_boton_1}></span></button>
                        <button value="2" onClick={this.cambiaManual} className={this.state.state_boton_2}>Norma E 030 <span className={this.state.marker_boton_2}></span></button>
                        
                    </Col>
                    <Col sm={9} className="Contenido_Manuales">
                        <ContenidoManual class_pdf={this.state.state_manual_1} url_pdf="./manual.pdf" titulo_pdf="Manual de Uso de la Plataforma"/>
                        <ContenidoManual class_pdf={this.state.state_manual_2} url_pdf="./RM-355-2018-VIVIENDA.pdf" titulo_pdf="Norma E 030"/>
                        
                    </Col>
                </Row>
            </Container>
        )
    }
}
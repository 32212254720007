import React from 'react';
import { MDBNavLink } from 'mdbreact';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

//Stilos

export default class BanerBusqueda extends React.Component {
    render(){
        return(
            <div className="carrusel_Busqueda">
            <Carousel className="banner_Busqueda" interval={6000}>
                <Carousel.Item>
                    <img
                    className="img_busqueda"
                    src={this.props.ruta_img_1}
                    />
                    <Carousel.Caption  className="texto">
                    <a href={this.props.ruta_img_1} className="boton_ver_detalle_busqueda" target="blank" variant="dark">FullScreen</a>
                    </Carousel.Caption>
                </Carousel.Item>
                {/* <Carousel.Item>
                    <img
                    className="img_busqueda"
                    src={this.props.ruta_img_2}
                    />
                    <Carousel.Caption className="texto">
                    <a href={this.props.ruta_img_2} className="boton_ver_detalle_busqueda" target="blank"  variant="dark">FullScreen</a>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="img_busqueda"
                    src={this.props.ruta_img_3}
                    />
                    <Carousel.Caption  className="texto">
                    <a href={this.props.ruta_img_3} className="boton_ver_detalle_busqueda" target="blank" variant="dark">FullScreen</a>
                    </Carousel.Caption>
                </Carousel.Item> */}
            </Carousel>
            </div>
        );
    }
}

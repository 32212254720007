
import React, {Component} from 'react';


export class DetalleCatalogo extends Component{
    render(){
        return(
            <div className="">
                <p>Estas accediendo a la sección con ID: {this.props.match.params.tipo_ensayo} {this.props.match.params.id}</p>
                {/* <SideBar className="Sidebar"/> */}
                <embed src="https://www.cisco.com/c/dam/global/es_mx/assets/ofertas/desconectadosanonimos/routing/pdfs/brochure_redes.pdf" type="application/pdf" width="60%" height="400px" />
                
            </div>
        )
    }
}

import React, {Component} from 'react';
import { Nav, Container, Row, Col } from 'react-bootstrap';
import { FaRegEye} from 'react-icons/fa';
import './busqueda.css';
//seccion banner detail
import BanerBusqueda from './BannerBusqueda';
//import axios
import axios from 'axios';

export class Busqueda extends Component{

    constructor(props) {
        super(props);
        this.state = {
            state_docu_busqueda: 'Seccion_PDF_Busqueda_oculto',
            state_img_ARcGIS: 'Seccion_IMG_Busqueda',
            value_busqueda: 'Ancón',
            loading_Busqueda: true,
            url_axios_Busqueda: 'https://perfilessuelosbackend.sencico.gob.pe/busqueda/Ancón',
            url_axios_Base: 'https://perfilessuelosbackend.sencico.gob.pe/busqueda/',
            datos_Busqueda: [],
            departamento_b:'',
            provincia_b:'',
            distrito_b:'',
            masw_b:'',
            mam_b:'',
            microtremores_b:'',
            perforacion_b:'',
            vmin_b:'',
            vmax_b:'',
            tmin_b:'',
            tmax_b:'',
            instituciones_b:[],
            documentacion_b:'./TABLAS/P-1.pdf',
            img1_b:'',
            img2_b:'',
            img3_b:'',
            loading_Filtros: true,
            url_axios_Filtros: 'https://perfilessuelosbackend.sencico.gob.pe/filtros',
            filtros_provincia:[],
        }
        this.cambiaContenidoBusqueda = this.cambiaContenidoBusqueda.bind(this);
        this.cuando_cambia_busqueda = this.cuando_cambia_busqueda.bind(this);
    }
    cuando_cambia_busqueda = (event) => {
        this.setState({value_busqueda: event.target.value});
        this.Obtener_Datos_Filter_Busqueda(this.state.url_axios_Base+event.target.value);
    }
    //Contenido Busqueda
    cambia_status_Busqueda=(ruta)=>{
        this.setState({
          url_axios_Busqueda:ruta,
          loading_Busqueda:true
        });
    }
      contenido_tipo_Busqueda(){
        if (this.state.loading_Busqueda){
          return(<span>...</span>)
        }
        return(
          this.state.datos_Busqueda.map(
            dato =><h5 className="subtitulo_descripcion">:</h5>
          )
        )
      };
      contenido_instituciones_Busqueda(){
        if (this.state.loading_Busqueda){
          return(<span>...</span>)
        }
        return(this.state.instituciones_b.map(
        dato =><span>{dato}<br/></span>)
        )
      };

      async Obtener_Datos_Filter_Busqueda(data_ingresa){
        await axios.get(data_ingresa)
        .then(response =>{
          console.log(response);
          this.setState({datos_Busqueda: response.data, loading_Busqueda:false, departamento_b: response.data.departamento, provincia_b: response.data.provincia, distrito_b:response.data.distrito, masw_b:response.data.masw, mam_b:response.data.mam,microtremores_b:response.data.microtremores,perforacion_b:response.data.perforacion,vmin_b:response.data.v_min, vmax_b:response.data.v_max, tmin_b:response.data.t_min, tmax_b:response.data.t_max,instituciones_b:response.data.instituciones.split(","),documentacion_b:response.data.documentacion,img1_b:response.data.img1,img2_b:response.data.img2,img3_b:response.data.img3})
        })
        .catch(e => {console.log(e)})
      }
      async Obtener_Datos_Busqueda(){
        await axios.get(this.state.url_axios_Busqueda)
        .then(response =>{
          console.log(response);
          this.setState({datos_Busqueda: response.data, loading_Busqueda:false, departamento_b: response.data.departamento, provincia_b: response.data.provincia, distrito_b:response.data.distrito, masw_b:response.data.masw, mam_b:response.data.mam,microtremores_b:response.data.microtremores,perforacion_b:response.data.perforacion,vmin_b:response.data.v_min, vmax_b:response.data.v_max, tmin_b:response.data.t_min, tmax_b:response.data.t_max,instituciones_b:response.data.instituciones.split(","),documentacion_b:response.data.documentacion,img1_b:response.data.img1,img2_b:response.data.img2,img3_b:response.data.img3})
        })
        .catch(e => {console.log(e)})
      }
      
    cambiaContenidoBusqueda(e){
        if(e.target.value == '1'){
            this.setState({
                state_docu_busqueda: 'Seccion_PDF_Busqueda',
                state_img_ARcGIS: 'Seccion_IMG_Busqueda_oculto',
                state_references: 'Seccion_REF_Busqueda_oculto',
            })
        }
        if(e.target.value == '2'){
            this.setState({
                state_docu_busqueda: 'Seccion_PDF_Busqueda_oculto',
                state_img_ARcGIS: 'Seccion_IMG_Busqueda',
                state_references: 'Seccion_REF_Busqueda_oculto',
            })
        }
    }
    contenido_filtros(){
      if (this.state.loading_Filtros){
        return(<span>...</span>)
      }
      return(
        this.state.filtros_provincia.map(
        dato =><option value={dato.distrito.nombre_distrito}>{dato.distrito.nombre_distrito} {dato.distrito.referencia}</option>
        )
      )
    }


    async Obtener_Datos_filtro(){
      await axios.get(this.state.url_axios_Filtros)
      .then(response =>{
        console.log(response.data.distritos);
        console.log(response.data.provincias);
        this.setState({filtros_provincia: response.data.distritos, loading_Filtros:false,})
      })
      .catch(e => {console.log(e)})
    }

    componentDidMount() {
        this.Obtener_Datos_Busqueda();
        this.Obtener_Datos_Filter_Busqueda();
        this.Obtener_Datos_filtro();
    }
    render(){
        return(
            <Container className="contenedor_busquedas">
            <Row>
                <Col sm={12} className="busquedas">
                    <div className="Controles_Busqueda">
                        <label className="Seleccion_Departamento">
                            Seleccione Lugar:
                            <select value={this.state.value_busqueda} onChange={this.cuando_cambia_busqueda} className="select_box_busqueda">
                              <option selected value="Ancón">Ancón (Dep.Lima)</option>
                              {this.contenido_filtros()}
                            {/* <optgroup label="Dep. Amazónas - Distritos:"><option value="Chachapoyas">Chachapoyas</option></optgroup>
                        <optgroup label="Dep. Arequipa - Distritos:"><option value="Yanahuara">Yanahuara</option><option value="Mollendo">Mollendo</option><option value="Alto Selva Alegre">Alto Selva Alegre</option></optgroup>
                        <optgroup label="Dep. Cajamarca - Distritos:"><option value="Jaén">Jaén</option></optgroup>
                        <optgroup label="Dep. Cusco - Distritos:"><option value="Cusco">Cusco</option><option value="San Jerónimo">San Jerónimo</option></optgroup>
                        <optgroup label="Dep. Huancavelica - Distritos:"><option value="Huaytará">Huaytará</option></optgroup>
                        <optgroup label="Dep. Ica - Distritos:"><option value="Ica">Ica</option><option value="Pisco">Pisco</option><option value="San Clemente">San Clemente</option></optgroup>
                        <optgroup label="Dep. La Libertad - Distritos:"><option value="El Porvenir">El Porvenir</option><option value="Trujillo">Trujillo</option><option value="Victor Larco Herrera">Victor Larco Herrera</option><option value="Ascope">Ascope</option></optgroup>
                        <optgroup label="Dep. Lambayeque - Distritos:"><option value="Lambayeque">Lambayeque</option></optgroup>
                        <optgroup label="Dep. Lima - Distritos:">
                          <option value="Ancón" selected>Ancón</option>
                          <option value="Ate">Ate</option>
                          <option value="Barranco">Barranco</option>
                          <option value="Lima">Lima</option>
                          <option value="Chorrillos">Chorrillos</option>
                          <option value="El Agustino">El Agustino</option>
                          <option value="Independencia">Independencia</option>
                          <option value="La Molina">La Molina</option>
                          <option value="Lince">Lince</option>
                          <option value="Los Olivos">Los Olivos</option>
                          <option value="Lurín">Lurín</option>
                          <option value="Magdalena del Mar">Magdalena del Mar</option>
                          <option value="Puente Piedra">Puente Piedra</option>
                          <option value="Rimac">Rimac</option>
                          <option value="San Borja">San Borja</option>
                          <option value="San Juan de Lurigancho">San Juan de Lurigancho</option>
                          <option value="San Juan de Miraflores">San Juan de Miraflores</option>
                          <option value="Villa el Salvador">Villa el Salvador</option>
                          <option value="Bellavista">Bellavista</option>
                          <option value="Chaclacayo">Chaclacayo</option>
                          <option value="Comas">Comas</option>
                          <option value="Pueblo Libre">Pueblo Libre</option>
                          <option value="Lurigancho">Lurigancho</option>
                          <option value="San Luis">San Luis</option>
                          <option value="San Martin de Porres">San Martín de Porres</option>
                          <option value="Santa Anita">Santa Anita</option>
                          <option value="Santiago de Surco">Santiago de Surco</option>
                          <option value="Surquillo">Surquillo</option>
                          <option value="Callao">Callao</option>
                          <option value="Carabayllo">Carabayllo</option>
                          <option value="Villa Maria del Triunfo">Villa Maria del Triunfo</option>
                          <option value="Pachacamac">Pachacamac</option>
                          <option value="Cieneguilla">Cieneguilla</option>
                          <option value="San Isidro">San Isidro</option>
                          <option value="San Bartolo">San Bartolo</option>
                          <option value="La Perla">La Perla</option>
                          <option value="Santa Rosa">Santa Rosa</option>
                          <option value="Ventanilla">Ventanilla</option>
                          <option value="Miraflores">Miraflores</option>
                          <option value="La Victoria">La Victoria</option>
                          <option value="Chilca">Chilca</option>
                          <option value="San Antonio">San Antonio</option>
                          <option value="San Miguel">San Miguel</option>
                          <option value="Pucusana">Pucusana</option>
                        </optgroup>
                        <optgroup label="Dep. Loreto - Distritos:"><option value="Yurimaguas">Yurimaguas</option></optgroup>
                        <optgroup label="Dep. Piura"><option value="Castilla">Castilla</option></optgroup>
                        <optgroup label="Dep. San Martín - Distritos:"><option value="Tarapoto">Tarapoto</option><option value="Moyobamba">Moyobamba</option></optgroup>
                        <optgroup label="Dep. Tacna - Distritos:"><option value="Tacna">Tacna</option></optgroup> */}
                            </select>
                        
                        </label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={3} className="Descripcion_Busqueda">
                    <h5 className="subtitulo_descripcion">Ubicación:</h5>
                    <span>Departamento: {this.state.departamento_b}</span><br/>
                    <span>Provincia: {this.state.provincia_b}</span><br/>
                    <span>Distrito: {this.state.distrito_b}</span><br/>
                    <br/>
                    
                    <h5 className="subtitulo_descripcion">Ensayos Realizados:</h5>
                    
                    <span>MASW ({this.state.masw_b})</span><br/>
                    <span>MAM ({this.state.mam_b})</span><br/>
                    <span>Microtremores ({this.state.microtremores_b})</span><br/>
                    <span>Perforación ({this.state.perforacion_b})</span>
                    <br/><br/>
                    <h5 className="subtitulo_descripcion">Descripción:</h5>
                    <p>
                    <span><strong>V<sub>30 min (m/s)</sub>: </strong>{this.state.vmin_b}</span><br/>
                    <span><strong>V<sub>30 max (m/s)</sub>: </strong>{this.state.vmax_b}</span><br/>
                    <span><strong>T<sub>(s) min </sub>: </strong>{this.state.tmin_b}</span><br/>
                    <span><strong>T<sub>(s) max </sub>: </strong>{this.state.tmax_b}</span><br/>
                    </p>{/* 
                    <h5 className="subtitulo_descripcion">Instituciones Aportantes:</h5>
                    {this.contenido_instituciones_Busqueda()}
                     */}
                </Col>
                <Col sm={9} className="ContDoc_Busqueda">
                    <Nav variant="tabs" defaultActiveKey="imagenes" className="navegador_vistas"> 
                        <Nav.Item>
                            <Nav.Link eventKey="documentacion"><button className="boton_control_busqueda" value="1" onClick={this.cambiaContenidoBusqueda} >Documentación</button></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="imagenes"><button className="boton_control_busqueda" value="2" onClick={this.cambiaContenidoBusqueda} >Mapa</button></Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div className="Vistas_Busqueda">
                        <embed src={this.state.documentacion_b} type="application/pdf" className={this.state.state_docu_busqueda} />
                        <div className={this.state.state_img_ARcGIS}>
                        <BanerBusqueda 
                        ruta_img_1={this.state.img1_b}/>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>

        )
    }
}
import React, {Component} from 'react';
import './manual.css';

export default class ContenidoManual extends React.Component {
    render(){
        return(
            <div className={this.props.class_pdf}>
                <p className="Titulo_manual"> {this.props.titulo_pdf}</p>
                <embed src={this.props.url_pdf} type="application/pdf" className="Seccion_PDF" />
            </div>
        )
    }
}
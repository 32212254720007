import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './referencias.css';
//seccion banner detail

export class Referencias extends Component{
    render(){
        return(
            <Container className="Content_Refeencias">
                <Row>
                    <Col sm={12}>
                        <h4 className="Titulo_Referencias">Referencias</h4>
                        <div className="Contenido_Referencias">
                            <ol>
                                <li>C. Ortiz (2019). Servicio de consultoría para la recopilación, validación y sistematización de perfiles del suelo en base a la velocidad de ondas de corte. SENCICO.</li>
                                <li>GEOINSTRUMENTS (2017). Servicio de Consultoría para la determinación del comportamiento Dinámico del suelo en función de la velocidad de propagación de ondas. SENCICO.</li>
                                <li>TERRASOLUTIONS (2018). Servicio de Consultoría para ejecución del Estudio Caracterización Geotécnica y Geofísica de estaciones acelerométricas. SENCICO.</li>
                                <li>CISMID (2018) Microzonificación Sísmica en los distritos de la Molina y Chorrillos. Ministerio de Vivienda, Construcción y Saneamiento.</li>
                                <li>CISMID (2013 al 2015) Microzonificación Sísmica en los distritos de Selva Alegre, Carabayllo, Cusco, El Agustino, Carmen de la Legua Reynoso, san Miguel, Santa Rosa y San Juan de Miraflores. Ministerio de Vivienda, Construcción y Saneamiento.</li>
                                <li>CISMID (2016 al 2018) Microzonificación Sísmica en los distritos de la Perla, Santiago de Surco, santa Anita, Jaén, bellavista, El porvenir, San Bartolo, Surquillo, Catacaos, Lambayeque, Larco Herrera y Trujillo. Ministerio de Vivienda, Construcción y Saneamiento.</li>
                                <li>Aguilar, Z. (2017). Servicio de Consultoría para el estudio de Mecánica de Suelos y Geotecnia para los terrenos destinados a la construcción de la Villa Deportiva Panamericana Parque Nº26.Ministerio de Transportes y Comunicaciones.</li>
                                <li>Red Acelerográfica del Posgrado FIC-UNI (2017 al 2019). Estudios de Caracterización de sitio de Casetas Acelerográficas de Moyobamba, Chachapoyas Jaén, Tarapoto y Yurimaguas. Universidad Nacional de Ingeniería.</li>
                                <li>Soto, J. (2016). Evaluación de espectros de respuesta mediante el Análisis de Respuesta de sitio unidimensional en siete distritos de Lima. Tesis de pregrado. Universidad Nacional de Ingeniería.</li>
                                <li>Huanacuni, D. (2014).Determinación la caracterización dinámica de los suelos en la ciudad de Tacna. Tesis de pregrado, Escuela Profesional de Ingeniería Civil, Facultad de Ingeniería, Universidad Privada de Tacna.</li>
                                <li>Pérez, A. (2018). Factor de reducción del módulo de elasticidad de pequeñas a grandes deformaciones en suelos gravo-limosos del distrito de San Jerónimo – Cusco. Tesis de posgrado. Universidad Nacional de Ingeniería.</li>
                                <li>Riveros, G. (2019). Microzonificación Sísmica de Villa el Salvador. Tesis de pregrado en desarrollo. Universidad Nacional de Ingeniería.</li>
                                <li>Jorge Alva Hurtado Ingenieros E.I.R.L. Estudios de Mecánica de Suelos. Consultorías varias</li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}
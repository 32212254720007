import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import './seccion_acceso.css';
import {Map, InfoWindow, LatLngBounds, Marker, GoogleApiWrapper} from 'google-maps-react';
import Titulo from './etiqueta_titulo';

const style = {
  width: '90%',
  height: '300px',
  marginLeft: '-10px',
  border:'solid 2px #123456',
};

export class MapContainer extends Component {
  
  render() {
    return (
      <Container className="accesos_secciones">
        <Row>
        <Titulo class_titulo="titulo" titulo="Ubicanos"/>
          <Col>
          <Map
              google={this.props.google}
              style={style}
              initialCenter={{
                lat: -12.0852404,
                lng: -77.0066057
              }}
              zoom={15}
              onClick={this.onMapClicked}
            >
              <Marker onClick={this.onMarkerClick}
                      name={'Current Position'} />
            
            </Map>
          </Col>
        </Row>
      </Container>
      
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: ('AIzaSyBEN1zVUqxX4a32voiBUrmEl_8BeOIt4_I')
})(MapContainer);
import 'bootstrap/dist/css/bootstrap.min.css';
import './seccion_acceso.css';
import React from 'react';
//Stilos
export default class Descripcion extends React.Component {
    render(){
        return(
            <div className="bienvenida">
                <p className="b_descrip">
                    La Gerencia de Investigación y Normalización del Servicio Nacional de Capacitación para la Industria de la Construcción – SENCICO, les da la más cordial bienvenida a la Plataforma Virtual de Visualización Sistematizada de Perfiles de Suelo.
                    Nuestro principal objetivo es brindar información sobre las características de los perfiles sísmicos en puntos específicos del territorio nacional, los que fueron realizados por instituciones públicas y privadas, teniendo en cuenta que el Perú se ubica en una zona de alta amenaza sísmica. 
                    Esta plataforma presenta una recopilación de la información de perfiles sísmicos y constituye una herramienta para que los usuarios dispongan de las características preliminares del suelo a cimentar en puntos específicos; asimismo brinda indicadores para que profesionales dedicados a la gestión del riesgo de desastres puedan complementar los planes de protección civil ante la ocurrencia de sismos severos.
                    Agradecemos al Ministerio de Vivienda y Construcción, a la Universidad Nacional de Ingeniería, al Dr. Jorge Alva Hurtado,  a la empresa FERLOZA  SAC, así como a todos los profesionales  que han compartido su información desinteresadamente  para cumplir con el objetivo propuesto y  permitir que sean difundidos públicamente a través de esta plataforma. 
                    El contenido de esta plataforma virtual, es con fines informativos, académicos y de investigación, y no reemplaza los requisitos solicitados en las Normas NTE E.050 y NTE E.030 ni puede ser utilizado para fines comerciales.
                </p>
            </div>
        );
    }

}

import catalogo from './img1.png';
import catalogo1 from './img2.png';
import catalogo2 from './portalwebSencico.png';
import React from 'react';
import { MDBNavLink } from 'mdbreact';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import './banner.css';

//Stilos

export default class BanerPrincipal extends React.Component {
    render(){
        return(
            <div className="carrusel">
            <Carousel className="banner" interval={3000}>
                <Carousel.Item>
                    <img
                    className="d-block altura"
                    src={catalogo1}
                    alt="First slide"
                    />
                    <div className="contraste"></div>
                    <Carousel.Caption  className="texto">
                    {/* <h3 className="titu_banner">Bienvenido a la Plataforma Virtual de Visualización Sistematizada de Perfiles de Suelo.</h3>
                    <p className="desc_banner">
                        La Gerencia de Investigación y Normalización del Servicio Nacional de Capacitación para la Industria de la Construcción – SENCICO, les da la más cordial bienvenida a la Plataforma Virtual de Visualización Sistematizada de Perfiles de Suelo.
                    </p> */}
                    <Button variant="light"><MDBNavLink className="navega_banner" to='./CatalogoWeb'>Ir al Catálogo</MDBNavLink></Button>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block altura"
                    src={catalogo}
                    alt="Second slide"
                    />
                    <div className="contraste"></div>
                    <Carousel.Caption className="texto">
                    {/* <h3 className="titu_banner">Documentación relacionada a la Información impartida</h3>
                    <p className="desc_banner">Nuestro principal objetivo es brindar información sobre las características de los perfiles sísmicos en puntos específicos del territorio nacional, los que fueron realizados por instituciones públicas y privadas, teniendo en cuenta que el Perú se ubica en una zona de alta amenaza sísmica. 
                    </p> */}
                    <Button variant="light"><MDBNavLink className="navega_banner" to='./Documentacion'>Ir a la Documentación</MDBNavLink></Button>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block altura"
                    src={catalogo}
                    alt="Third slide"
                    />
                    <div className="contraste"></div>
                    <Carousel.Caption  className="texto">
                    {/* <h3>Instituciones Aportantes</h3>
                    <p>Descripción detallada de Instituciones que aportan información de ensayos Geofísicos a Nivel Nacional.</p> */}
                    <Button variant="light"><MDBNavLink className="navega_banner" to='./Instituciones'>Ver Instituciones Aportantes</MDBNavLink></Button>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            </div>
        );
    }
}

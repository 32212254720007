import React from 'react';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
  MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBFormInline } from 'mdbreact';

import logo from './logo.svg';
import logo1 from './sencicoLogo1.png';
import twitter from './twitter.png';
import facebook from './facebook.png';
import youtube from './youtube.png';


import { FaHome, FaInfo, FaSearch, FaBook, FaAdjust, FaPeopleCarry, FaPencilAlt, FaPhoneAlt, FaPhone, FaMobile, FaMobileAlt, FaMap, FaMapMarked, FaInternetExplorer,
  FaFileAlt, FaFileArchive, FaBuilding, FaTv, FaPencilRuler, FaYoutube, FaFacebookMessenger, FaFacebookSquare } from 'react-icons/fa';


class FullPageIntroWithFixedTransparentNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false,
      colapsable: "colapsable_inactive"
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
    if(this.state.colapsable=="colapsable"){
      this.setState({colapsable:"colapsable_inactive"})
    }
    else{
      this.setState({colapsable:"colapsable"})
    }
  } 
  
  render() {
    return (
        <div>
            <MDBNavbar className="navnav"  fixed="top" color="bg-dark" dark expand="md" scrolling transparent>
              <MDBNavbarBrand >
                <strong><a href="https://www.sencico.gob.pe/" target="_blank"><img src={logo1} className="App-logo" alt="logo" /></a></strong> 
              </MDBNavbarBrand>
              <MDBNavbarBrand className="name_gral" href="/">
                <p className="name_page">PLATAFORMA  VIRTUAL DE VISUALIZACIÓN<br/>SISTEMATIZADA DE PERFILES DE SUELO</p>
              </MDBNavbarBrand>
              <br></br>
              {!this.state.isWideEnough && <MDBNavbarToggler onClick={this.onClick} />}
              <MDBCollapse isOpen={this.state.collapse} navbar className={this.state.colapsable}>
                <MDBNavbarNav  color="white" left>
                  <MDBNavItem className="navitem" active>
                    <MDBNavLink to="/"><strong title="Ir al inicio"><span className="dropd">Inicio</span> </strong><FaHome title="Ir al Inicio" className="icon_nav"/></MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem  className="navitem" active>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret active className="dropd">
                        <strong title="Desplegar mapa de ensayos geofísicos" className="dropd">Mapa De Ensayos <FaMap title="Desplegar mapa de ensayos geofísicos" className="icon_nav"/></strong>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu className="dropdown-default" transparent>
                        <MDBDropdownItem ><MDBNavLink to="/CatalogoMovil" className="submenu"><span className="vers" title="Mapa para dispositivos móviles">Versión Móvil </span><FaMobileAlt title="Mapa para dispositivos móviles" className="icon_vers"/></MDBNavLink></MDBDropdownItem>
                        <MDBDropdownItem ><MDBNavLink to="/CatalogoWeb" className="submenu"><span className="vers" title="Mapa para ordenadores">Versión Web   </span><FaTv title="Mapa para ordenadores" className="icon_vers"/></MDBNavLink></MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem  className="navitem" active>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret active className="dropd">
                        <strong title="Documentación recopilada de ensayos geofísicos" className="dropd">Documentación <FaFileArchive title="Documentación recopilada de ensayos geofísicos" className="icon_nav"/></strong>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu className="dropdown-default" transparent>
                        <MDBDropdownItem ><MDBNavLink to="/Busqueda" className="submenu"><span className="vers" title="Búsqueda de Ensayos recopialdos por region">Búsquedas </span><FaSearch title="Búsqueda de Ensayos recopialdos por region" className="icon_vers"/></MDBNavLink></MDBDropdownItem>
                        <MDBDropdownItem ><MDBNavLink to="/Definiciones" className="submenu"><span className="vers" title="Definiciones empleadas en el portal web">Definiciones   </span><FaPencilAlt title="Definiciones empleadas en el portal web" className="icon_vers"/></MDBNavLink></MDBDropdownItem>
                        <MDBDropdownItem ><MDBNavLink to="/Documentacion" className="submenu"><span className="vers" title="Documentación referente al portal">Documentación   </span><FaFileArchive title="Documentación referente al portal" className="icon_vers"/></MDBNavLink></MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem  className="navitem" active>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret active className="dropd">
                        <strong title="Información adicional recopilada" className="dropd">Información Adicional<FaInfo title="Información adicional recopilada" className="icon_nav"/></strong>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu className="dropdown-default" transparent>
                        <MDBDropdownItem ><MDBNavLink to="/Manual" className="submenu"><span className="vers" title="Manual de uso de la plataforma">Manual </span><FaBook title="Manual de uso de la plataforma" className="icon_vers"/></MDBNavLink></MDBDropdownItem>
                        <MDBDropdownItem ><MDBNavLink to="/Referencias" className="submenu"><span className="vers" title="Referencias referente a los ensayos">Referencias   </span><FaPeopleCarry title="Referencias referente a los ensayos" className="icon_vers"/></MDBNavLink></MDBDropdownItem>
                        <MDBDropdownItem ><MDBNavLink to="/Instituciones" className="submenu"><span className="vers" title="Relación de Instituciones Aportantes">Instituciones </span><FaBuilding title="Relación de Instituciones Aportantes" className="icon_vers"/></MDBNavLink></MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem>
                  
                </MDBNavbarNav>
                <MDBNavbarNav right>
                  {/* <MDBNavItem>
                    <MDBFormInline waves>
                      <div className="md-form my-0">
                        <input className="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search" />
                      </div>
                    </MDBFormInline>
                  </MDBNavItem> */}
                  <MDBNavItem className="navitem2">
                    <MDBNavbarBrand>
                      <a href="https://www.youtube.com/channel/UCMmZfZ-E-dpWDkn6Ce9KHqA" target="blank"><img src={youtube} className="icon_youtube"/></a> 
                    </MDBNavbarBrand>
                  </MDBNavItem>
                  <MDBNavItem  className="navitem2">
                    <MDBNavbarBrand>
                      <a href="https://web.facebook.com/sencicoperu/?_rdc=1&_rdr" target="blank"><img src={facebook} className="icon_facebook"/></a> 
                    </MDBNavbarBrand>
                  </MDBNavItem>
                  {/* <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                        <MDBIcon fab icon={facebook} />Acciones
                      </MDBDropdownToggle>
                      <MDBDropdownMenu title="Acciones" className="dropdown-default">
                        <MDBDropdownItem href="/">Home</MDBDropdownItem>
                        <MDBDropdownItem href="/Descripcion">Descripción</MDBDropdownItem>
                        <MDBDropdownItem href="/Referencias">Referencias</MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem> */}
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>
      </div>
    );
  }
}

export default FullPageIntroWithFixedTransparentNavbar;
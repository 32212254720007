import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Component} from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBPopover, MDBPopoverBody,
MDBPopoverHeader, MDBTooltip } from 'mdbreact';
import Seccion from './seccion_instituciones';
import './Instituciones.css';
import MapExample from './MapExample';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLayerGroup, FaList, FaRegBookmark, FaRev, FaCloudDownloadAlt,FaRegEyeSlash, FaRegEye} from 'react-icons/fa';
import { BsGrid } from "react-icons/bs";
import { MdZoomOutMap, MdFilterList } from "react-icons/md";
import {GiUnionJack} from "react-icons/gi";

export class Instituciones extends Component{
    
  render() {
      return(
        <Container className="accesos_instituciones">
            {/* <FaLayerGroup/>
            <FaRegBookmark/>
            <FaRev/>
            <FaList/>
            <FaCloudDownloadAlt/><FaRegEyeSlash/>
            <FaRegEye/>
            <BsGrid/><MdZoomOutMap/><MdFilterList/>
            <GiUnionJack className="color-icon"/> */}
            <Row className="row_secciones">
                    <Seccion 
                        class_col="seccion"
                        class_contenido="contenido"
                        class_descripcion="Descripcion"
                        titulo="SENCICO"
                        src_image="./sencico.jpg"
                        //src_image="https://www.educacionenred.pe/noticia-img/2012/12/029779-foto-sencico-capacitara-construccion-pobladores-puno-cajamarca-www-sencico-gob-pe.jpg"
                        //src_image="https://www.educacionenred.pe/noticia-img/2012/02/016027-foto-admision-sencico-2012-mas-mil-jovenes-participan-hoy-examen-ingreso-escuela.jpg"
                        //src_image="https://app.sencico.gob.pe/prd/wscertificado/Imagenes/sencicoLogo2.png"
                        class_image="image-catalogo"
                        parrafo="Empresas Encargadas: GEOINSTRUMENT, TERRASOLUTION."
                        class_direccion="Direccion"
                        redirige="https://www.sencico.gob.pe/"
                        class_nav="naveg"
                        nombre_button="Ir al portal"
                    />
                    <Seccion 
                        class_col="seccion"
                        class_contenido="contenido"
                        class_descripcion="Descripcion"
                        titulo="MVCS"
                        src_image="./min_vivienda_cismid.png"
                        class_image="image-catalogo"
                        parrafo="Institución Encargada: UNI - CISMID."
                        class_direccion="Direccion"
                        redirige="https://www.gob.pe/vivienda/"
                        class_nav="naveg"
                        nombre_button="Ir al portal"
                    />
                    <Seccion 
                        class_col="seccion"
                        class_contenido="contenido"
                        class_descripcion="Descripcion"
                        titulo="UNI"
                        //src_image="http://www.red-acelerografica-peru.com/themes/classic/img/logo-uni.png"
                        //src_image="https://img2.freepng.es/20181129/fyt/kisspng-national-university-of-engineering-university-of-n-fileuni-svg-wikimedia-commons-5bffa8aa1adff6.2534474515434815141101.jpg"
                        src_image="./uni-red-logo.png"
                        class_image="image-catalogo"
                        parrafo="Oficina Encargada: Red Acelerografica UPG FIC."
                        class_direccion="Direccion"
                        redirige="https://www.uni.edu.pe/"
                        class_nav="naveg"
                        nombre_button="Ir al portal"
                    />
                    <Seccion 
                        class_col="seccion"
                        class_contenido="contenido"
                        class_descripcion="Descripcion"
                        titulo="EXTERNOS"
                        src_image="./instituciones.jpg"
                        class_image="image-catalogo"
                        parrafo="Investigador: Dr. Jorge Alva Hurtado, Consultor: FERLOZA SAC."
                        class_direccion="Direccion"
                        redirige="http://www.jorgealvahurtado.com"
                        class_nav="naveg"
                        nombre_button="Ir al portal"
                    />
                    
                </Row>
            
        </Container>
    )
  }
}
import 'bootstrap/dist/css/bootstrap.min.css';
import './seccion_acceso.css';
import React from 'react';
//Stilos
export default class Titulo extends React.Component {
    render(){
        return(
            <div className="bienvenida_titulo">
                <h4 className="b_titulo">Bienvenido a la Plataforma Virtual de Visualización Sistematizada de Perfiles de Suelo.</h4>
            </div>
        );
    }

}

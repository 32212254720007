import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
//import './seccion_acceso.css';
import {MDBNavLink} from 'mdbreact';
//externals
import ReactDOM from 'react-dom';
import React from 'react';
//Stilos

export default class Seccion extends React.Component {
    render(){
        return(
            <Col sm={3} className={this.props.class_col}>
                <div className={this.props.class_contenido}>
                    <div className={this.props.class_descripcion}>
                        <h5>{this.props.titulo}</h5>
                        <img src={this.props.src_image} className={this.props.class_image}/>
                         <p>{this.props.parrafo}</p>
                    </div>
                    <button className={this.props.class_direccion} ><a className={this.props.class_nav} href={this.props.redirige} target='_blank'>{this.props.nombre_button}</a></button>
                </div>
            </Col>
        );
    }

}

import React, {Component} from 'react';
import './Documentacion.css';
//seccion banner detail
import { Nav, Container, Row, Col } from 'react-bootstrap';
import BanerDocumentacion from './BannerDocumentacion';
export class Documentacion extends Component{
    constructor(props) {
        super(props);
        this.state = {
            state_docu_Documentacion: 'Seccion_PDF_Documentacion_oculto',
            state_img_ARcGIS: 'Seccion_IMG_Documentacion',
            state_references: 'Seccion_REF_Documentacion_oculto',
        }
        this.cambiaContenidoDocumentacion = this.cambiaContenidoDocumentacion.bind(this);
    }
    cambiaContenidoDocumentacion(e){
        if(e.target.value == '1'){
            this.setState({
                state_docu_Documentacion: 'Seccion_PDF_Documentacion',
                state_img_ARcGIS: 'Seccion_IMG_Documentacion_oculto',
                state_references: 'Seccion_REF_Documentacion_oculto',
            })
        }
        if(e.target.value == '2'){
            this.setState({
                state_docu_Documentacion: 'Seccion_PDF_Documentacion_oculto',
                state_img_ARcGIS: 'Seccion_IMG_Documentacion',
                state_references: 'Seccion_REF_Documentacion_oculto',
            })
        }
    }

    render(){
        return(
            <Container className="contenedor_Documentacions">
            <Row>
                <Col sm={12} className="Documentacions">
                    <div className="Controles_Documentacion">
                        <label className="Seleccion_Departamento">
                            Documentación e Informe completo de los Ensayos Recopilados a Nivel Nacional
                        </label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={3} className="Descripcion_Documentacion">
                    <h5 className="subtitulo_descripcion">País:</h5>
                    <span>PERÚ</span>
                    <br/><br/>
                    
                    <h5 className="subtitulo_descripcion">Ensayos Realizados:</h5>
                    <span>MASW (527)</span><br/>
                    <span>MAM (32)</span><br/>
                    <span>Microtremores (1676)</span><br/>
                    <span>Perforación (36)</span>
                    <br/><br/>
                    <h5 className="subtitulo_descripcion">Descripción:</h5>
                    <p>
                        <span><strong>V<sub>s 30 min (m/s) </sub>: </strong>219</span><br/>
                        <span><strong>V<sub>s 30 max (m/s) </sub>: </strong>875</span><br/>
                        <span><strong>T<sub>(s) min</sub> : </strong>0.05</span><br/>
                        <span><strong>T<sub>(s) max</sub> : </strong>1.21</span>
                    </p>
                    <h5 className="subtitulo_descripcion">Instituciones Aportantes:</h5>
                    <span>TERRASOLUTIONS</span><br/>
                    <span>SENCICO</span><br/>
                    <span>CISMID</span><br/>
                    <span>GEOINSTRUMENTS</span><br/>
                    <span>Red Acelerográfica UPG-FIC-UNI</span><br/>
                    <span>Jorge Alva Hurtado Ingenieros</span><br/>
                </Col>
                <Col sm={9} className="ContDoc_Documentacion">
                    <Nav variant="tabs" defaultActiveKey="imagenes" className="navegador_vistas"> 
                        <Nav.Item>
                            <Nav.Link eventKey="documentacion"><button className="boton_control_Documentacion" value="1" onClick={this.cambiaContenidoDocumentacion} >Documentación</button></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="imagenes"><button className="boton_control_Documentacion" value="2" onClick={this.cambiaContenidoDocumentacion} >Mapas</button></Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div className="Vistas_Documentacion">
                        <embed src="./INFORME FINAL V4.pdf" type="application/pdf" className={this.state.state_docu_Documentacion} />
                        <div className={this.state.state_img_ARcGIS}>
                        <BanerDocumentacion 
                        ruta_img_1="./P-U 01 MAPA A NIVEL NACIONAL.jpg"
                        ruta_img_2="./P-U 02-MAPA A NIVEL PROVINCIAL.jpg"
                        ruta_img_3="./P-U 01 MAPA A NIVEL NACIONAL.jpg"/>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        )
    }
}
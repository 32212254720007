import 'bootstrap/dist/css/bootstrap.min.css';
import {Popover, OverlayTrigger, Button, Card, Navbar, Nav, NavDropdown, ListGroup, FormGroup} from 'react-bootstrap';
//externals
import ReactDOM from 'react-dom';
import React from 'react';
//Stilos
import ModalCaracteristicas from './modal_caracteristicas';
import "./catalogo.css"
import { MDBPopover, MDBPopoverHeader, MDBPopoverBody, MDBTooltip, MDBContainer, MDBRow, MDBCol, MDBMask, MDBView, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
//Logos
import logo_masw from './masw.png';
import logo_mam from './mam.png';
import logo_perforacion from './perforacion.png';
import logo_microtremor from './microtremor.png';
//importaciones para el mapa
import View from 'ol/View';
import Projection from 'ol/proj/Projection';
import Map from 'ol/Map';
import Tile from 'ol/layer/Tile';
import TileLayer from 'ol/layer/Tile';
import Vector from 'ol/layer/Vector';
import OSM from 'ol/source/OSM';
import Stamen from 'ol/source/Stamen';
import Control from 'ol/control/Control';
import Attribution from 'ol/control/Attribution';
import Zoom from 'ol/control/Zoom';
import ZoomToExtent from 'ol/control/ZoomToExtent';
import ZoomSlider from 'ol/control/ZoomSlider';
import FullScreen from 'ol/control/FullScreen';
import OverviewMap from 'ol/control/OverviewMap';
import Rotate from 'ol/control/Rotate';
import ScaleLine from 'ol/control/ScaleLine';
//import ZoomSlider from 'ol/source/ZoomSlider';
//importar estilos
import Style from 'ol/style/Style';
import BingMaps from 'ol/source/BingMaps';
//import para vectores

import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Fill, Stroke, Text, Circle} from 'ol/style';
import BaseLayer from 'ol/layer/Base';

//importar para geolocalizar 
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Icon from 'ol/style/Icon';
import { fromLonLat } from 'ol/proj';
import {transform} from 'ol/proj';

//importar para popup
import Overlay from 'ol/Overlay';
import {Datos} from './cuadro_datos'

// import icons
import { FaSearchLocation, FaLayerGroup, FaRegBookmark, FaList, FaRev, FaCloudDownloadAlt,FaRegEyeSlash, FaRegEye} from 'react-icons/fa';
import { BsGrid, BsFillLayersFill, BsLayersHalf } from "react-icons/bs";
import { MdZoomOutMap, MdFilterList } from "react-icons/md";
import {GiUnionJack, GiPositionMarker} from "react-icons/gi";
import {FiLayers} from "react-icons/fi";

//Geocode
import Geocode from "react-geocode";

//seccion banner detail
import BanerDetailMovil from './banner3';


//import axios
import axios from 'axios';

//SideBart
import Sidebar from "react-sidebar";
require('ol/ol.css');


export default class Mapaf extends React.Component {
  state = {
    modal_ini: false,
    modal_detail: false,
    modal_leyend:false,
    show_datos_punto:true
  }

  toggle_datos_punto = () =>{
      this.setState({
          show_datos_punto: !this.state.show_datos_punto,
      })
  }
  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

    constructor(props) {
        super(props);
        this.state = {
          sidebarOpen: false,
          show_datos_punto: true,
          estado_capa_masw: 'Activo',
          estado_capa_mam: 'Activo',
          estado_capa_microtremor: 'Activo',
          estado_capa_perforacion: 'Activo',
          tipo_ensayo:'',
          nombre_ensayo:'',
          departamento_ensayo:'',
          provincia_ensayo:'',
          distrito_ensayo:'',
          zona_ensayo: '',
          coordUTM1_ensayo:'',
          coordUTM2_ensayo:'',
          fuente_ensayo:'',
          periodo_ensayo: '',
          velcorte_ensayo:'',
          logo_ensayo:'',
          img1_ensayo: '',
          img2_ensayo:'',
          img3_ensayo:'',
          documentacion_ensayo:'',
          simbolo_ensayo:'',
          longitud_zoom:'',
          latitud_zoom:'',
          caracteristicas_puntos: 'sin_contenido_mapa_web_web',
          p_control: 'Control_Panel',
          ruta_ensayos_masw: 'https://perfilessuelosbackend.sencico.gob.pe/masw.geojson',
          ruta_ensayos_mam: 'https://perfilessuelosbackend.sencico.gob.pe/mam.geojson',
          ruta_ensayos_microtremor: 'https://perfilessuelosbackend.sencico.gob.pe/microtremor.geojson',
          ruta_ensayos_perforacion: 'https://perfilessuelosbackend.sencico.gob.pe/perforacion.geojson',
          panel_filtros: 'sin_panel_filtro',
          tipo_filtro: 'none',
          state_query: '0',
          tipo_departamento:'sin_Departamento',
          tipo_provincia: 'sin_Provincia',
          tipo_distrito: 'sin_Distrito',
          tipo_vs: 'sin_Vs',
          tipo_periodo: 'sin_Periodo',
          tipo_radio: 'sin_Radio',
          //Estados Iniciales 
          departamento: 'Lima',
          provincia: 'Lima',
          distrito: 'Ancón',
          vs: '0-200',
          periodo: '0-0.1',
          radio: '1',
          estado_boton_geoposition:'Inactivo',
          boton_geoposition: 'Geo_Inactivo',
          seccion_marcadores: 'seccion_marcadores_oculto',
          seccion_lista: 'seccion_lista_oculto',
          seccion_mapas: 'seccion_mapas_oculto',
          text_ensayos_web: 'text_ensayos_web',
          text_ensayos_web_ma: 'text_ensayos_web',
          text_ensayos_web_mi: 'text_ensayos_web',
          text_ensayos_web_pe: 'text_ensayos_web',
          //seccion total
          cantidad_ensayos_totales:0,
          //tabla filtros
          ensayos_masw:[],
          cantidad_ensayos_masw:'',
          url_axios_masw:'https://perfilessuelosbackend.sencico.gob.pe/masw.geojson/',
          loading_masw: true,

          ensayos_mam:[],
          cantidad_ensayos_mam:'',
          url_axios_mam:'https://perfilessuelosbackend.sencico.gob.pe/mam.geojson/',
          loading_mam: true,

          ensayos_microtremor:[],
          cantidad_ensayos_microtremor:'',
          url_axios_microtremor:'https://perfilessuelosbackend.sencico.gob.pe/microtremor.geojson/',
          loading_microtremor: true,
          
          ensayos_perforacion:[],
          cantidad_ensayos_perforacion:'',
          url_axios_perforacion:'https://perfilessuelosbackend.sencico.gob.pe/perforacion.geojson/',
          loading_perforacion: true,

          //activo tablas
          contenido_datos_masw: 'contenido_datos_activo',
          contenido_datos_mam: 'contenido_datos_inactivo',
          contenido_datos_microtremor: 'contenido_datos_inactivo',
          contenido_datos_perforacion: 'contenido_datos_inactivo',
          boton_control_uno:'botones_tablas_active',
          boton_control_dos:'botones_tablas',
          boton_control_tres:'botones_tablas',
          boton_control_cuatro:'botones_tablas',

          //Detail Control´s
          Contenido_Detalle_Ensayo_Movil: 'Contenido_Detalle_Ensayo_Movil',
          Contenido_Imagenes_Ensayo_Movil: 'Contenido_Imagenes_Ensayo_Movil_Inactive',
          Contenido_Documentacion_Ensayo_Movil: 'Contenido_Documentacion_Ensayo_Movil_Inactive',
          control_detalle_boton1: 'Control_Detalle_Ensayo_boton_active_movil',
          control_detalle_boton2: 'Control_Detalle_Ensayo_boton_inactive_movil',
          control_detalle_boton3: 'Control_Detalle_Ensayo_boton_inactive_movil',
          
          //
          lat_filter: -12.0917,
          lon_filter:	-77.0461,
          
          //filtros
          loading_Filtros: true,
          loading_Filtros_One: true,
          url_axios_Filtros: 'https://perfilessuelosbackend.sencico.gob.pe/filtros',
          filtros_distrito:[],
          filtros_provincias: [],
        };
        this.cuando_cambia = this.cuando_cambia.bind(this);
        this.cuando_cambia_departamento = this.cuando_cambia_departamento.bind(this);
        this.cuando_cambia_provincia = this.cuando_cambia_provincia.bind(this);
        this.cuando_cambia_distrito = this.cuando_cambia_distrito.bind(this);
        this.cuando_cambia_vs = this.cuando_cambia_vs.bind(this);
        this.cuando_cambia_periodo = this.cuando_cambia_periodo.bind(this);
        this.cuando_cambia_radio = this.cuando_cambia_radio.bind(this);
        this.cuando_cambia_ciudad = this.cuando_cambia_ciudad.bind(this);
        this.cuando_cambia_ano = this.cuando_cambia_ano.bind(this);
        this.cuando_cambia_fuente = this.cuando_cambia_fuente.bind(this);
        this.cuando_filtra= this.cuando_filtra.bind(this);
        this.center_marker = this.center_marker.bind(this);
        this.cambia_direccion = this.cambia_direccion.bind(this);
        this.cambia_lat_filter = this.cambia_lat_filter.bind(this);
        this.cambia_lon_filter = this.cambia_lon_filter.bind(this);
        this.busca_direccion = this.busca_direccion.bind(this);
        this.cambiaDetalle = this.cambiaDetalle.bind(this);    
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
        this.OSM = new TileLayer({
            source: new OSM(),
            visible: false
        });
        this.WaterColor = new TileLayer({
            source: new Stamen({
              layer: 'watercolor',
            }),
            visible: false
        });
        this.bingmap = new TileLayer({
            source: new BingMaps({
                key:'Akf58DQVppAPmtBLUolPS8RS9tBvBWJyyiT4cvgMwZIwMUGsAADIkedByoa6l_K1 ',
                imagerySet:'AerialWithLabelsOnDemand',//AerialWithLabelsOnDemand
            }),
            visible: false
        });
        this.bingmapRoad = new TileLayer({
          source: new BingMaps({
              key:'Akf58DQVppAPmtBLUolPS8RS9tBvBWJyyiT4cvgMwZIwMUGsAADIkedByoa6l_K1 ',
              imagerySet:'Road',//AerialWithLabelsOnDemand
          }),
          visible: true
        });
        this.ByN = new TileLayer({
          source: new Stamen({
            layer: 'toner',
          }),
          visible: false
        });
        this.terreno = new TileLayer({
          source: new Stamen({
            layer: 'terrain',
          }),
          visible: false
        });
        this.Etiquetas = new TileLayer({
          source: new Stamen({
            layer: 'terrain-labels',
          }),
          visible: true
        });
        this.style = new Style({
          fill: new Fill({
            color: 'rgba(215,215,215,0.3)'
          }),
          stroke: new Stroke({
            color: 'rgba(115,115,115,0.95)',
            width: 2
          }),
          text: new Text({
            font: '12px Calibri,sans-serif',
            fill: new Fill({
              color: '#000',
              width:5
            }),
            stroke: new Stroke({
              color: 'black',
              width: 5
            })
          })
      });
      this.sourceManual = new VectorSource({
        //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
        url: './peru-geojson-master/departamentos_joseph.geojson',
        format: new GeoJSON()
      });
      this.vectorLayer = new VectorLayer({
        name: 'Countries',
        source: this.sourceManual,
        style: this.style,
        visible:false
      });
      this.sourceManual1 = new VectorSource({
        //url: 'httpss://openlayers.org/en/latest/examples/data/geojson/countries.geojson',
        url: './peru-geojson-master/provincias_joseph.geojson',
        format: new GeoJSON()
      });
      this.vectorLayer1 = new VectorLayer({
        name: 'Countries',
        source: this.sourceManual1,
        style: this.style,
        visible:false
      });
      this.sourceManual2 = new VectorSource({
        url:'./peru-geojson-master/distritos_joseph_selected_lci_sm_ama_caj_lam_lali_areq_cu_ta_hu_aa_dm.geojson',
        format: new GeoJSON()
      });
      this.vectorLayer2 = new VectorLayer({
        name: 'Manual',
        source: this.sourceManual2,
        style: this.style,
        visible: false
      });
        //MASW
        this.fillMasw = new Fill({color: 'rgba(57,255,20,0.9)'});
        this.strokeMasw = new Stroke({color: 'rgba(0,0,0,0.9)',width: 1});
        this.styleMasw = new Style({
          image: new Circle({
            fill: this.fillMasw,
            stroke: this.strokeMasw,
            radius: 8
            }),
          //image: new Icon({
          //  anchor: [0.5, 46],
          //  anchorXUnits: 'fraction',
          //  anchorYUnits: 'pixels',
          //  src: logo_masw,
          //  src: logo_masw,
          //  src: 'httpss://openlayers.org/en/latest/examples/data/icon.png',
          //  scale: 0.05,
          //}),
            fill:this.fillMasw,
            stroke: this.strokeMasw,
            text: new Text({
            //text: "Punto !!",
            font: 'bold 11px sans-serif red',
          }),
        });  
        this.sourceMasw = new VectorSource({
          /* features: (new GeoJSON()).readFeatures(
            {"type":"FeatureCollection","features": [
              {"type": "Feature","geometry": {"type": "Point","coordinates": [-77.048, -12.0912]},"properties": {"Parametros": "Hipocentrales","Latitud": "-77.048","Longitud": "-12.0912","Ubicacion": "Lima","dep": "2","Magnitud": "5.6 ML","name": "Sismo de Lima","Reporte": "Instituto Geofísico del Perú","Fecha": "1917/06/16","Intensidad": "II Lima, III Ica","Imagen": "https://www.culture.gouv.fr/Wave/image/memoire/1599/sap40_d0004328_v.jpg"}}]},
            {featureProjection: 'EPSG:3857'}),
          format: new GeoJSON() */
          url: this.state.ruta_ensayos_masw,
          format: new GeoJSON()
        });
        this.vectorMasw = new VectorLayer({
          name: 'Manual',
          source: this.sourceMasw,
          style: this.styleMasw,
          visible: true
        });

        //MAM
        this.fillMam = new Fill({color: 'rgba(87,35,100,.9)'});
        this.strokeMam = new Stroke({color: 'rgba(0,0,0,.9)',width: 1});
        this.styleMam = new Style({
          image: new Circle({
            fill: this.fillMam,
            stroke: this.strokeMam,
            radius: 8
            }),
          //image: new Icon({
          //  anchor: [0.5, 46],
          //  anchorXUnits: 'fraction',
          //  anchorYUnits: 'pixels',
          //  src: logo_mam,
          //  scale: 0.02
          //}),
            fill:this.fillMam,
            stroke: this.strokeMam,
            text: new Text({
            //text: "Punto !!",
            font: 'bold 11px sans-serif',
            color: 'red',
          }),
        });  
        this.sourceMam = new VectorSource({
          url: this.state.ruta_ensayos_mam,
          format: new GeoJSON()
        });
        this.vectorMam = new VectorLayer({
          name: 'Manual',
          source: this.sourceMam,
          style: this.styleMam,
          visible: true
        });

        //Microtremor
        this.fillMicrotremor = new Fill({color: 'rgba(255,0,0,.9)'});
        this.strokeMicrotremor = new Stroke({color: 'rgba(0,0,0,.9)',width: 1});
        this.styleMicrotremor = new Style({
          image: new Circle({
            fill: this.fillMicrotremor,
            stroke: this.strokeMicrotremor,
            radius: 7
            }),
          //image: new Icon({
          //  anchor: [0.5, 46],
          //  anchorXUnits: 'fraction',
          //  anchorYUnits: 'pixels',
          //  src: logo_microtremor,
          //  scale: 0.04
          //}),
            fill:this.fillMicrotremor,
            stroke: this.strokeMicrotremor,
            text: new Text({
            //text: "Punto !!",
            font: 'bold 11px sans-serif',
            color: 'red',
          }),
        });  
        this.sourceMicrotremor = new VectorSource({
          url: this.state.ruta_ensayos_microtremor,
          format: new GeoJSON()
        });
        this.vectorMicrotremor = new VectorLayer({
          name: 'Manual',
          source: this.sourceMicrotremor,
          style: this.styleMicrotremor,
          visible: true
        });

        //Perforacion
        this.fillPerforacion = new Fill({color: 'rgba(0,170,228,0.9)'});
        this.strokePerforacion = new Stroke({color: 'rgba(0,0,0,1)',width: 1});
        this.stylePerforacion = new Style({
          image: new Circle({
            fill: this.fillPerforacion,
            stroke: this.strokePerforacion,
            radius: 8
            }),
          //image: new Icon({
          //  anchor: [0.5, 46],
          //  anchorXUnits: 'fraction',
          //  anchorYUnits: 'pixels',
          //  src: logo_perforacion,
          //  scale: 0.02
          //}),
            fill:this.fillPerforacion,
            stroke: this.strokePerforacion,
            text: new Text({
            //text: "Punto !!",
            font: 'bold 11px sans-serif',
            color: 'red',
          }),
        });  
        this.sourcePerforacion = new VectorSource({
          url: this.state.ruta_ensayos_perforacion,
          format: new GeoJSON()
        });
        this.vectorPerforacion = new VectorLayer({
          name: 'Manual',
          source: this.sourcePerforacion,
          style: this.stylePerforacion,
          visible: true
        });

        this.view = new View({
            center: fromLonLat([-76.048, -9.4913]),
            zoom: 6,
            minZoom: 2,
            maxZoom: 28,
            //projection: new Projection({
            //    code: 'EPSG:4326',
            //    units: 'degrees'
            //})
        });

        this.extend = new ZoomToExtent({
            extent: [
                813079.7791264898, 5929220.284081122,
                848966.9639063801, 5936863.986909639
              ],
            label: "E",
            tipLabel: "Extender para centrar mapa"
        });
        this.myControlLayers = new Control({
          element: document.getElementById('control_prueba'),
        });
        //Popup
        
        /* this.pe = document.getElementById('popup');
        this.popup = new Overlay({
          element: this.pe,
          id: 'popup',
          positioning: 'bottom-center',
          autoPan: true,
          autoPanAnimation: {
            duration: 250
          },
          stopEvent: false,
          offset: [0, -50]
        }); */
        //Mapa
        this.map =  new Map({
          view: this.view,
          controls: [ 
            new Zoom({className:"bg-dark zoom", zoomInTipLabel:"Acerca el Mapa",zoomOutTipLabel:"Aleja el Mapa"}), 
            //new Attribution({className:"bg-dark atribution", collapsible:true, collapseLabel: "Joseph Montero", tipLabel:"Atribuciones" }), 
            new ZoomSlider({duration:100}), 
            //new ZoomToExtent({className:"bg-dark",tipLabel: "Centrar mapa"}), 
            new FullScreen({tipLabel:"Pantalla completa",className:"fullscreen"}), 
            new ScaleLine({ minWidth:100}), 
            new OverviewMap({layers:[this.OSM,this.WaterColor,this.bingmap,this.ByN,this.terreno],collapsible:true, tipLabel:"Sobre vista del mapa"}),
            new Rotate({autoHide:true,className:"bg-dark rotate", tipLabel:"Rotar pantalla para centrar"})],
          layers: [this.OSM, this.WaterColor, this.Etiquetas, this.bingmap, this.bingmapRoad, this.ByN, this.terreno, this.vectorLayer, this.vectorLayer1, this.vectorLayer2, this.vectorMicrotremor, this.vectorPerforacion, this.vectorMam, this.vectorMasw],
          // target: this.refs.mapContainer
        });
        //this.map.addControl(this.myControlLayers);

        //GeoPosicionamiento
        this.strokePosition = new Stroke({color: 'rgba(255,0,0,0.9)', width: 5});
        this.fillPosition = new Fill({color: 'rgba(0,255,0,0.9)', width: 5});
        this.geoposition = new Geolocation({
          trackingOptions: { enableHighAccuracy: true },
          projection: this.view.getProjection()
        });
        // this.geoposition.setTracking('true');
        // display popup on click
        this.acercamiento = (evt) => {
          this.feature = this.map.getFeaturesAtPixel(evt.pixel);
          this.point_on_click = this.map.getCoordinateFromPixel(evt.pixel);
          this.point_on_click_lat_lon = transform(this.point_on_click,'EPSG:900913', 'EPSG:4326');
          this.setState({lat_filter:this.point_on_click_lat_lon[1], lon_filter:this.point_on_click_lat_lon[0],});
          console.log(this.point_on_click_lat_lon);
          console.log(this.feature);
          console.log(this.feature.length);
          if (this.feature.length>=1){
            console.log(this.feature[0].get('name'));
            console.log(this.feature[0].get('latitud'));
            console.log(this.feature[0].get('longitud'));
            //console.log(this.feature[0].get('rainfall'));
            //console.log(this.feature[0].get('population'));
            
            //this.container.innerHTML = '<p>Joseph</p>';
            this.coordenadas = transform(evt.coordinate,'EPSG:900913', 'EPSG:4326');
            //this.pe1 = document.getElementsByClassName('popup');
            //this.pe2 = document.getElementById('popup');
            //this.coordenadasS = [this.feature[0].get('longitud'), this.feature[0].get('latitud')];
            //this.popup.setPosition(evt.coordinate);
            //this.map.addOverlay(this.popup);
            //this.elemento= this.popup.getElement(this.pe);
            //console.log(this.pe1);
            //console.log(this.pe2);
            //this.pe2.innerHTML='<p><a href="/Ensayo/1">Ver</a>Prueba de envio recursivo <a href="https://www.'+this.feature[0].get('name')+'.com" target="blank">'+this.feature[0].get('name')+'</a></p>';
            //this.toggle('_ini');

            //this.map.setView(
            //  new View({
            //    center: fromLonLat(this.coordenadasS),
            //    zoom: 18
            //  })
            //);
            this.createcomponente(this.feature[0].get('tipo_ensayo'),this.feature[0].get('name'),this.feature[0].get('departamento'),this.feature[0].get('provincia'),this.feature[0].get('distrito'),this.feature[0].get('zona'),this.feature[0].get('coordUTM1'),this.feature[0].get('coordUTM2'),this.feature[0].get('fuente'),this.feature[0].get('periodo'),this.feature[0].get('velcorte'),this.feature[0].get('logo'),this.feature[0].get('img1'),this.feature[0].get('img2'),this.feature[0].get('img3'),this.feature[0].get('documentacion'),this.feature[0].get('latitud'),this.feature[0].get('longitud'));
            this.setState({lat_filter:this.feature[0].get('latitud'),lon_filter:this.feature[0].get('longitud'),});
            //this.popup.setPosition(evt.coordinate);
            //console.log(evt.coordinate);
            //this.popup.getElement().innerHTML='<button onClick={this.Mostrar} className="primary">Estas aquí</button>';
            //this.popup.getElement().innerHTML='<p>'+evt.coordinate[0]+'</p>'
            //this.map.addOverlay(this.popup);
          }
          else{this.setState({caracteristicas_puntos:'sin_contenido_mapa_web_web',})}
          //this.element=this.popup.getElement();
          //this.coordenadas = transform(evt.coordinate,'EPSG:900913', 'EPSG:4326');
          //this.popup.setPosition(this.coordenadas);
          //evt.popover('show');
          //this.coordenadas = transform(evt.coordinate,'EPSG:900913', 'EPSG:4326');
          //console.log('Vamos a mostrar el popup con datos del punto', this.coordenadas);
          //this.popup.innerHTML = '<p>You clicked here:</p>';  
          //this.popup.setPosition(this.coordenadas);
          //this.map.addOverlay(this.popup);
          //this.popup.innerHTML='<p>You clicked here:</p>';
          //console.log('Joseph Montero Inga');
        };
        this.caracteristicas = (evt) => {
          this.feature = this.map.getFeaturesAtPixel(evt.pixel);
          if (this.feature.length==1){            
            this.popup.setPosition(evt.coordinate);
            console.log(evt.coordinate);
            this.popup.getElement().innerHTML='<div class="table-responsive table-sm table-hover table-striped text-nowrap tabla_caracteristicas"><h4>Características</h4><table class="table"><thead className="black white-text"><tr><th scope="col">Id</th><th scope="col">Nombre</th><th scope="col">Símbolo</th></tr></thead><tbody><tr><th scope="row">1</th><td>Cell</td><td>Cell</td></tr><tr><th scope="row">2</th><td>Cell</td><td>Cell</td></tr><tr><th scope="row">3</th><td>Cell</td><td>Cell</td></tr></tbody></table></div>';
            //document.getElementById("popup2").innerHTML='<div class="table-responsive table-sm table-hover table-striped text-nowrap tabla_caracteristicas"><h4>Características</h4><table class="table"><thead className="black white-text"><tr><th scope="col">Id</th><th scope="col">Nombre</th><th scope="col">Símbolo</th></tr></thead><tbody><tr><th scope="row">1</th><td>Cell</td><td>Cell</td></tr><tr><th scope="row">2</th><td>Cell</td><td>Cell</td></tr><tr><th scope="row">3</th><td>Cell</td><td>Cell</td></tr></tbody></table></div>';
            //this.popup.getElement().innerHTML='<p>'+evt.coordinate[0]+'</p>'
            this.map.addOverlay(this.popup);
            
          }
          else{
            this.map.removeOverlay(this.popup);
          }
        };
        this.map.on('singleclick', this.acercamiento);
        this.map.on('dblclick', this.caracteristicas);
        this.map.on()
        // cambiar el cursor cuando pasas a un evento
        this.cambiacursor = (e) => {
          //if(e.dragging){
          //  e.popover('destroy');
          //};
          this.pixel = this.map.getEventPixel(e.originalEvent);
          this.hit = this.map.hasFeatureAtPixel(this.pixel);
          this.map.getTarget().style.cursor = this.hit ?'pointer':'';
        };
        this.map.on('pointermove',this.cambiacursor);
    }
    createcomponente=(tipo_en,nomb,depa,prov,ensay,zona,_UTM1,_UTM2,fuent,periodo,velco,logo,img1,img2,img3,docum,latitud,longitud)=>{
      console.log("muestro datos");
      console.log(tipo_en);
      this.setState({
        caracteristicas_puntos:'contenido_mapa',
        tipo_ensayo:tipo_en,
        nombre_ensayo:nomb,
        departamento_ensayo:depa,
        provincia_ensayo:prov,
        distrito_ensayo:ensay,
        zona_ensayo: zona,
        coordUTM1_ensayo:_UTM1,
        coordUTM2_ensayo:_UTM2,
        fuente_ensayo:fuent,
        periodo_ensayo: periodo,
        velcorte_ensayo:velco,
        logo_ensayo:logo,
        img1_ensayo: img1,
        img2_ensayo:img2,
        img3_ensayo:img3,
        documentacion_ensayo:docum,
        longitud_zoom:longitud,
        latitud_zoom:latitud
      });
      console.log(this.state.tipo_ensayo);
      console.log(this.state.latitud_zoom, this.state.longitud_zoom)
      // this.toggleShowBM()
      if (tipo_en=="MASW") {this.setState({simbolo_ensayo:'simbolo_leyenda_t_masw'})};
      if (tipo_en=="MAM") {this.setState({simbolo_ensayo:'simbolo_leyenda_t_mam'})};
      if (tipo_en=="Microtremor") {this.setState({simbolo_ensayo:'simbolo_leyenda_t_microtremor'})};
      if (tipo_en=="Perforacion") {this.setState({simbolo_ensayo:'simbolo_leyenda_t_perforacion'})};
    }

    hacer_zoom = () =>{
      this.coordenadasS = [this.state.longitud_zoom, this.state.latitud_zoom];
      this.map.setView(
        new View({
          center: fromLonLat(this.coordenadasS),
          zoom: 18
        })
      );
    }
    centrar_mapa = () =>{
      this.map.setView(
        new View({
          center: fromLonLat([-76.048, -9.4913]),
          zoom: 6,
        })
      );
    }
    cerrar_caracteristicas = () =>{
      this.setState({
        caracteristicas_puntos:'sin_contenido_mapa'
      })
    }
    cerrar_filtros = () =>{
      this.setState({
        panel_filtros:'sin_panel_filtro'
      })
    }
    cuando_cambia = (event) => {
      this.setState({
        tipo_filtro: event.target.value
      });
      if(event.target.value=='none'){
        this.setState({
          state_query: '0',
          tipo_departamento:'sin_Departamento',
          tipo_provincia: 'sin_Provincia',
          tipo_distrito: 'sin_Distrito',
          tipo_vs: 'sin_Vs',
          tipo_periodo: 'sin_Periodo',
          tipo_radio: 'sin_Radio',
        })
      }
      if(event.target.value=='departamento'){
        this.setState({
          state_query: '1',
          tipo_departamento:'Departamento',
          tipo_provincia: 'sin_Provincia',
          tipo_distrito: 'sin_Distrito',
          tipo_vs: 'sin_Vs',
          tipo_periodo: 'sin_Periodo',
          tipo_radio: 'sin_Radio',
        })
      }
      if(event.target.value=='provincia'){
        this.setState({
          state_query: '2',
          tipo_departamento:'sin_Departamento',
          tipo_provincia: 'Provincia',
          tipo_distrito: 'sin_Distrito',
          tipo_vs: 'sin_Vs',
          tipo_periodo: 'sin_Periodo',
          tipo_radio: 'sin_Radio',
        })
      }
      if(event.target.value=='distrito'){
        this.setState({
          state_query: '3',
          tipo_departamento:'sin_Departamento',
          tipo_provincia: 'sin_Provincia',
          tipo_distrito: 'Distrito',
          tipo_vs: 'sin_Vs',
          tipo_periodo: 'sin_Periodo',
          tipo_radio: 'sin_Radio',
        })
      }
      if(event.target.value=='vs'){
        this.setState({
          state_query:'4',
          tipo_departamento:'sin_Departamento',
          tipo_provincia: 'sin_Provincia',
          tipo_distrito: 'sin_Distrito',
          tipo_vs: 'Vs',
          tipo_periodo: 'sin_Periodo',
          tipo_radio: 'sin_Radio',
        })
      }
      if(event.target.value=='periodo'){
        this.setState({
          state_query:'5',
          tipo_departamento:'sin_Departamento',
          tipo_provincia: 'sin_Provincia',
          tipo_distrito: 'sin_Distrito',
          tipo_vs: 'sin_Vs',
          tipo_periodo: 'Periodo',
          tipo_radio: 'sin_Radio',
        })
      }
      if(event.target.value=='area'){
        this.setState({
          state_query:'6',
          tipo_departamento:'sin_Departamento',
          tipo_provincia: 'sin_Provincia',
          tipo_distrito: 'sin_Distrito',
          tipo_vs: 'sin_Vs',
          tipo_periodo: 'sin_Periodo',
          tipo_radio: 'Radio',
        })
      }
    }
    cuando_cambia_departamento = (event) =>{this.setState({departamento: event.target.value})}
    cuando_cambia_provincia = (event) =>{this.setState({provincia: event.target.value})}
    cuando_cambia_distrito = (event) =>{this.setState({distrito: event.target.value})}
    cuando_cambia_vs = (event) => {this.setState({vs:event.target.value})}
    cuando_cambia_periodo = (event) => {this.setState({periodo:event.target.value})}
    cuando_cambia_radio = (event) => {this.setState({radio:event.target.value})}
    cuando_cambia_ciudad = (event) => {this.setState({ciudad: event.target.value})}
    cuando_cambia_ano = (event) => {this.setState({ano: event.target.value})}
    cuando_cambia_fuente = (event) =>{this.setState({fuente: event.target.value})}
    cuando_filtra = (event) => {
      console.log(event);
      //alert('Este es tipo de filtro seleccionado: '+ this.state.tipo_filtro);
      if(this.state.state_query=='1'){
        this.path_temporal_masw='https://perfilessuelosbackend.sencico.gob.pe/masw.geojson/'+this.state.state_query+'@'+this.state.departamento;
        this.path_temporal_mam='https://perfilessuelosbackend.sencico.gob.pe/mam.geojson/'+this.state.state_query+'@'+this.state.departamento;
        this.path_temporal_microtremor='https://perfilessuelosbackend.sencico.gob.pe/microtremor.geojson/'+this.state.state_query+'@'+this.state.departamento;
        this.path_temporal_perforacion='https://perfilessuelosbackend.sencico.gob.pe/perforacion.geojson/'+this.state.state_query+'@'+this.state.departamento;
        this.sourceMasw.setUrl(this.path_temporal_masw);
        this.sourceMasw.refresh();
        this.sourceMam.setUrl(this.path_temporal_mam);
        this.sourceMam.refresh();
        this.sourceMicrotremor.setUrl(this.path_temporal_microtremor);
        this.sourceMicrotremor.refresh();
        this.sourcePerforacion.setUrl(this.path_temporal_perforacion);
        this.sourcePerforacion.refresh();
      }
      if(this.state.state_query=='2'){
        this.path_temporal_masw='https://perfilessuelosbackend.sencico.gob.pe/masw.geojson/'+this.state.state_query+'@'+this.state.provincia;
        this.path_temporal_mam='https://perfilessuelosbackend.sencico.gob.pe/mam.geojson/'+this.state.state_query+'@'+this.state.provincia;
        this.path_temporal_microtremor='https://perfilessuelosbackend.sencico.gob.pe/microtremor.geojson/'+this.state.state_query+'@'+this.state.provincia;
        this.path_temporal_perforacion='https://perfilessuelosbackend.sencico.gob.pe/perforacion.geojson/'+this.state.state_query+'@'+this.state.provincia;
        this.sourceMasw.setUrl(this.path_temporal_masw);
        this.sourceMasw.refresh();
        this.sourceMam.setUrl(this.path_temporal_mam);
        this.sourceMam.refresh();
        this.sourceMicrotremor.setUrl(this.path_temporal_microtremor);
        this.sourceMicrotremor.refresh();
        this.sourcePerforacion.setUrl(this.path_temporal_perforacion);
        this.sourcePerforacion.refresh();
      }
      if(this.state.state_query=='3'){
        this.path_temporal_masw='https://perfilessuelosbackend.sencico.gob.pe/masw.geojson/'+this.state.state_query+'@'+this.state.distrito;
        this.path_temporal_mam='https://perfilessuelosbackend.sencico.gob.pe/mam.geojson/'+this.state.state_query+'@'+this.state.distrito;
        this.path_temporal_microtremor='https://perfilessuelosbackend.sencico.gob.pe/microtremor.geojson/'+this.state.state_query+'@'+this.state.distrito;
        this.path_temporal_perforacion='https://perfilessuelosbackend.sencico.gob.pe/perforacion.geojson/'+this.state.state_query+'@'+this.state.distrito;
        this.sourceMasw.setUrl(this.path_temporal_masw);
        this.sourceMasw.refresh();
        this.sourceMam.setUrl(this.path_temporal_mam);
        this.sourceMam.refresh();
        this.sourceMicrotremor.setUrl(this.path_temporal_microtremor);
        this.sourceMicrotremor.refresh();
        this.sourcePerforacion.setUrl(this.path_temporal_perforacion);
        this.sourcePerforacion.refresh();
      }
      if(this.state.state_query=='4'){
        this.path_temporal_masw='https://perfilessuelosbackend.sencico.gob.pe/masw.geojson/'+this.state.state_query+'@'+this.state.vs;
        this.path_temporal_mam='https://perfilessuelosbackend.sencico.gob.pe/mam.geojson/'+this.state.state_query+'@'+this.state.vs;
        this.path_temporal_microtremor='https://perfilessuelosbackend.sencico.gob.pe/microtremor.geojson/'+this.state.state_query+'@'+this.state.vs;
        this.path_temporal_perforacion='https://perfilessuelosbackend.sencico.gob.pe/perforacion.geojson/'+this.state.state_query+'@'+this.state.vs;
        this.sourceMasw.setUrl(this.path_temporal_masw);
        this.sourceMasw.refresh();
        this.sourceMam.setUrl(this.path_temporal_mam);
        this.sourceMam.refresh();
        this.sourceMicrotremor.setUrl(this.path_temporal_microtremor);
        this.sourceMicrotremor.refresh();
        this.sourcePerforacion.setUrl(this.path_temporal_perforacion);
        this.sourcePerforacion.refresh();
      }
      if(this.state.state_query=='5'){
        this.path_temporal_masw='https://perfilessuelosbackend.sencico.gob.pe/masw.geojson/'+this.state.state_query+'@'+this.state.periodo;
        this.path_temporal_mam='https://perfilessuelosbackend.sencico.gob.pe/mam.geojson/'+this.state.state_query+'@'+this.state.periodo;
        this.path_temporal_microtremor='https://perfilessuelosbackend.sencico.gob.pe/microtremor.geojson/'+this.state.state_query+'@'+this.state.periodo;
        this.path_temporal_perforacion='https://perfilessuelosbackend.sencico.gob.pe/perforacion.geojson/'+this.state.state_query+'@'+this.state.periodo;
        this.sourceMasw.setUrl(this.path_temporal_masw);
        this.sourceMasw.refresh();
        this.sourceMam.setUrl(this.path_temporal_mam);
        this.sourceMam.refresh();
        this.sourceMicrotremor.setUrl(this.path_temporal_microtremor);
        this.sourceMicrotremor.refresh();
        this.sourcePerforacion.setUrl(this.path_temporal_perforacion);
        this.sourcePerforacion.refresh();
      }
      if(this.state.state_query=='6'){
        this.path_temporal_masw='https://perfilessuelosbackend.sencico.gob.pe/masw.geojson/'+this.state.state_query+'@'+this.state.lat_filter+',,'+this.state.lon_filter+',,'+this.state.radio;
        this.path_temporal_mam='https://perfilessuelosbackend.sencico.gob.pe/mam.geojson/'+this.state.state_query+'@'+this.state.lat_filter+',,'+this.state.lon_filter+',,'+this.state.radio;
        this.path_temporal_microtremor='https://perfilessuelosbackend.sencico.gob.pe/microtremor.geojson/'+this.state.state_query+'@'+this.state.lat_filter+',,'+this.state.lon_filter+',,'+this.state.radio;
        this.path_temporal_perforacion='https://perfilessuelosbackend.sencico.gob.pe/perforacion.geojson/'+this.state.state_query+'@'+this.state.lat_filter+',,'+this.state.lon_filter+',,'+this.state.radio;
        this.sourceMasw.setUrl(this.path_temporal_masw);
        this.sourceMasw.refresh();
        this.sourceMam.setUrl(this.path_temporal_mam);
        this.sourceMam.refresh();
        this.sourceMicrotremor.setUrl(this.path_temporal_microtremor);
        this.sourceMicrotremor.refresh();
        this.sourcePerforacion.setUrl(this.path_temporal_perforacion);
        this.sourcePerforacion.refresh();
      }
                
      event.preventDefault();
      /* this.setState({
        panel_filtros:'sin_panel_filtros'
      }); */
      
      //this.map.removeLayer(this.vectorMasw);
      //this.sourceMasw_filtrado.setUrl('https://perfilessuelosbackend.sencico.gob.pe/masw_nombre/Lima');
      //this.vectorMasw_filtrado.setVisible(true);
      console.log(this.state.ruta_ensayos_masw);
    }
    
    onSetSidebarOpen(open) {
      this.setState({ sidebarOpen: open });
    }
    //Sección Detalle
    cambiaDetalle(e){
      if(e.target.value == '1'){
        this.setState({
          control_detalle_boton3: 'Control_Detalle_Ensayo_boton_inactive_movil',
          control_detalle_boton2: 'Control_Detalle_Ensayo_boton_inactive_movil',
          control_detalle_boton1: 'Control_Detalle_Ensayo_boton_active_movil',
          Contenido_Documentacion_Ensayo_Movil: 'Contenido_Documentacion_Ensayo_Movil_Inactive',
          Contenido_Imagenes_Ensayo_Movil: 'Contenido_Imagenes_Ensayo_Movil_Inactive',
          Contenido_Detalle_Ensayo_Movil: 'Contenido_Detalle_Ensayo_Movil',
        })
      }
      if(e.target.value == '2'){
        this.setState({
          control_detalle_boton3: 'Control_Detalle_Ensayo_boton_inactive_movil',
          control_detalle_boton1: 'Control_Detalle_Ensayo_boton_inactive_movil',
          control_detalle_boton2: 'Control_Detalle_Ensayo_boton_active_movil',
          Contenido_Documentacion_Ensayo_Movil: 'Contenido_Documentacion_Ensayo_Movil_Inactive',
          Contenido_Detalle_Ensayo_Movil: 'Contenido_Detalle_Ensayo_Movil_Inactive',
          Contenido_Imagenes_Ensayo_Movil: 'Contenido_Imagenes_Ensayo_Movil',
        })
      }
      if(e.target.value == '3'){
        this.setState({
          control_detalle_boton1: 'Control_Detalle_Ensayo_boton_inactive_movil',
          control_detalle_boton2: 'Control_Detalle_Ensayo_boton_inactive_movil',
          control_detalle_boton3: 'Control_Detalle_Ensayo_boton_active_movil',
          Contenido_Imagenes_Ensayo_Movil: 'Contenido_Imagenes_Ensayo_Movil_Inactive',
          Contenido_Detalle_Ensayo_Movil: 'Contenido_Detalle_Ensayo_Movil_Inactive',
          Contenido_Documentacion_Ensayo_Movil: 'Contenido_Documentacion_Ensayo_Movil',
        })
      }
    }
    contenido_filtros(){
      if (this.state.loading_Filtros){
        return(<span>...</span>)
      }
      return(
        this.state.filtros_distrito.map(
        dato =><option value={dato.distrito.nombre_distrito}>{dato.distrito.nombre_distrito} {dato.distrito.referencia}</option>
        )
      )
    }

    contenido_filtros_provincias(){
      if (this.state.loading_Filtros_One){
        return(<span>...</span>)
      }
      return(
        this.state.filtros_provincias.map(
        dato =><option value={dato.provincia.nombre_provincia}>{dato.provincia.nombre_provincia} {dato.provincia.referencia}</option>
        )
      )
    }
    async Obtener_Datos_filtro(){
      await axios.get(this.state.url_axios_Filtros)
      .then(response =>{
        console.log(response.data.distritos);
        console.log(response.data.provincias);
        this.setState({filtros_distrito: response.data.distritos, filtros_provincias: response.data.provincias, loading_Filtros:false, loading_Filtros_One:false})
      })
      .catch(e => {console.log(e)})
    }

    componentDidMount() {
        this.map.setTarget(this.refs.mapContainer);
        //popup
        this.popup = new Overlay({
          element: ReactDOM.findDOMNode(this).querySelector('#popup'),
        });
        this.Obtener_Datos_filtro();
    }
    //Buscador de Direcciones
    cambia_direccion(event) {
      this.setState({value: event.target.value});
      console.log(event.target.value);
    }
  
    busca_direccion(event) {
      this.convert_direccion(this.state.value);
      event.preventDefault();
    }
    //Cambia Filtro de latitud
    cambia_lat_filter(event) {
      this.setState({lat_filter: event.target.value});
      console.log(event.target.value);
    }
    //Cambia Filtro de longitud
    cambia_lon_filter(event) {
      this.setState({lon_filter: event.target.value});
      console.log(event.target.value);
    }

    convert_direccion(direccion){
      // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
      Geocode.setApiKey("AIzaSyBEN1zVUqxX4a32voiBUrmEl_8BeOIt4_I");
      // set response language. Defaults to english.
      Geocode.setLanguage("latam");
      // set response region. Its optional.
      // A Geocoding request with region=es (Spain) will return the Spanish city.
      Geocode.setRegion("pe");
      // Enable or disable logs. Its optional.
      Geocode.enableDebug();
      // Get latidude & longitude from address.
      Geocode.fromAddress(direccion).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          console.log(lat, lng);
          this.center_marker(lat,lng,17);
          this.setState({
            lat_filter: lat,
            lon_filter: lng,
          });
        },
        error => {
          console.error(error);
        }
      );
    }
    toggleShowBM=()=>{
      this.bingmap.setVisible(true);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
    };
    toggleShowBMR=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(true);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
    };
    toggleShowWC=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(true);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
    };
    toggleShowOSM=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(true);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(false);
    };
    toggleShowByN=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(true);
      this.terreno.setVisible(false);
    }
    toggleShowTerreno=()=>{
      this.bingmap.setVisible(false);
      this.bingmapRoad.setVisible(false);
      this.OSM.setVisible(false);
      this.WaterColor.setVisible(false);
      this.ByN.setVisible(false);
      this.terreno.setVisible(true);
    }
    panel = () => {
      if(this.state.p_control == 'Control_Panel'){
        this.setState({
          p_control:'Control_Panel_Extend'
        });
        console.log(this.state.p_control)
      }
      else{
        this.setState({
          p_control:'Control_Panel'
        });
        console.log(this.state.p_control)
      }
    }
    filtrar_punto = () => {
      if(this.state.panel_filtros == 'sin_panel_filtro'){
        this.setState({
          panel_filtros:'panel_filtro'
        });
        console.log(this.state.panel_filtros)
      }
      else{
        this.setState({
          panel_filtros:'sin_panel_filtro'
        });
        console.log(this.state.panel_filtros)
      }
    }
    reset_filtros = () =>{
      this.path='https://perfilessuelosbackend.sencico.gob.pe/masw.geojson';
      this.path1='https://perfilessuelosbackend.sencico.gob.pe/mam.geojson';
      this.path2='https://perfilessuelosbackend.sencico.gob.pe/microtremor.geojson';
      this.path3='https://perfilessuelosbackend.sencico.gob.pe/perforacion.geojson';
      this.sourceMasw.setUrl(this.path);
      this.sourceMasw.refresh();
      this.sourceMam.setUrl(this.path1);
      this.sourceMam.refresh();
      this.sourceMicrotremor.setUrl(this.path2);
      this.sourceMicrotremor.refresh();
      this.sourcePerforacion.setUrl(this.path3);
      this.sourcePerforacion.refresh();
      this.setState({panel_filtros:'sin_panel_filtro'})
    }
    Check=()=>{
      console.log(this.vectorMasw.getVisible());
      if(this.vectorMasw.getVisible()){
        //document.getElementById('check').setState('checked');
        this.vectorMasw.setVisible(false);
        this.setState({estado_capa_masw:'Inactivo', text_ensayos_web: 'text_ensayos_web_inactivo'})
      }
      else{
        this.vectorMasw.setVisible(true);
        this.setState({estado_capa_masw:'Activo', text_ensayos_web: 'text_ensayos_web'})
      }
    };
    Check1=()=>{
      console.log(this.vectorMam.getVisible());
      if(this.vectorMam.getVisible()){
        //document.getElementById('check').setState('checked');
        this.vectorMam.setVisible(false);
        this.setState({estado_capa_mam:'Inactivo', text_ensayos_web_ma: 'text_ensayos_web_inactivo'})
      }
      else{
        this.vectorMam.setVisible(true);
        this.setState({estado_capa_mam:'Activo', text_ensayos_web_ma: 'text_ensayos_web'})
      }
    };
    Check2=()=>{
      console.log(this.vectorMicrotremor.getVisible());
      if(this.vectorMicrotremor.getVisible()){
        //document.getElementById('check').setState('checked');
        this.vectorMicrotremor.setVisible(false);
        this.setState({estado_capa_microtremor:'Inactivo', text_ensayos_web_mi: 'text_ensayos_web_inactivo'})
      }
      else{
        this.vectorMicrotremor.setVisible(true);
        this.setState({estado_capa_microtremor:'Activo', text_ensayos_web_mi: 'text_ensayos_web'})
      }
    };
    Check3=()=>{
      console.log(this.vectorPerforacion.getVisible());
      if(this.vectorPerforacion.getVisible()){
        //document.getElementById('check').setState('checked');
        this.vectorPerforacion.setVisible(false);
        this.setState({estado_capa_perforacion:'Inactivo', text_ensayos_web_pe: 'text_ensayos_web_inactivo'})
      }
      else{
        this.vectorPerforacion.setVisible(true);
        this.setState({estado_capa_perforacion:'Activo', text_ensayos_web_pe: 'text_ensayos_web'})
      }
    };
    Check4=()=>{
      if(this.vectorLayer.getVisible()){
        this.vectorLayer.setVisible(false);
        this.vectorLayer1.setVisible(false);
        this.vectorLayer2.setVisible(false)
      }
      else{
        this.vectorLayer.setVisible(true);
        this.vectorLayer1.setVisible(false);
        this.vectorLayer2.setVisible(false)
      }
    };
    Check5=()=>{
      if(this.vectorLayer1.getVisible()){
        this.vectorLayer.setVisible(false);
        this.vectorLayer1.setVisible(false);
        this.vectorLayer2.setVisible(false)
      }
      else{
        this.vectorLayer.setVisible(false);
        this.vectorLayer1.setVisible(true);
        this.vectorLayer2.setVisible(false)
      }
    };
    Check6=()=>{
      if(this.vectorLayer2.getVisible()){
        this.vectorLayer.setVisible(false);
        this.vectorLayer1.setVisible(false);
        this.vectorLayer2.setVisible(false)
      }
      else{
        this.vectorLayer.setVisible(false);
        this.vectorLayer1.setVisible(false);
        this.vectorLayer2.setVisible(true)
      }
    };
    Check_boton_geoposition=()=>{
      console.log(this.vectorLayerGP.getVisible());
      if(this.vectorLayerGP.getVisible()){
        //document.getElementById('check').setState('checked');
        this.vectorLayerGP.setVisible(false);
        this.setState({estado_boton_geoposition:'Inactivo'})
      }
      else{
        this.vectorLayerGP.setVisible(true);
        this.setState({estado_boton_geoposition:'Activo'})
      }
    };
    GeoPositionInMap=()=>{
      console.log('Entrando a Geoposicionamiento');
      this.geoposition.setTracking('true');
      this.geoposition.setProjection();
      // this.geoposition.getPosition();
      this.geoposition.on('change',this.ObtenUbicacion);
      this.setState({boton_geoposition:'Geo_Activo',estado_boton_geoposition:'Activo'});
    };
    ObtenUbicacion=()=>{
      console.log("Aparezco cuando acepta el tracking");
      //console.log(this.geoposition.getPosition());
      this.coordinates= transform(this.geoposition.getPosition(),'EPSG:3857','EPSG:4326');
      this.geoposition.setTracking(false);
      console.log(this.coordinates[0]);
      console.log(this.coordinates[1]);
      this.setState({lat_filter:this.coordinates[1], lon_filter:this.coordinates[0]});
      this.iconFeature = new Feature({
        geometry: new Point(fromLonLat([this.coordinates[0], this.coordinates[1]])),
        tipo_ensayo: 'Geo Posicionamiento',
        name: 'Geo Posicionamiento',
        coordUTM1: parseFloat(this.coordinates[0]).toFixed(4),
        coordUTM2: parseFloat(this.coordinates[1]).toFixed(4),
        fuente: <a href='https://www.linkedin.com/in/joseph-marlon-montero-inga-aa3aa7106/' target='_blank'>Fuente del Programador</a>,
      
      });
      
      this.iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          //rc: 'httpss://openlayers.org/en/latest/examples/data/icon.png',
          src: 'https://executiveedge.ie/wp-content/plugins/google-maps/assets/images/icons/greenmarker256.png',
          scale: 0.21
        }),
        stroke: this.strokePosition,
        fill: this.fillPosition,
        text: new Text({
          text: "Tú estas aquí !!",
				  font: 'bold 11px sans-serif',
        })
      });
      
      this.iconFeature.setStyle(this.iconStyle);
      
      this.vectorSourceGP = new VectorSource({
        features: [this.iconFeature]
      });
      
      this.vectorLayerGP = new VectorLayer({
        source: this.vectorSourceGP
      });
      
      this.map.setView(
        new View({
          center: fromLonLat(this.coordinates),
          zoom: 15
        })
      );
      this.map.addLayer(this.vectorLayerGP);
    };
    center_marker(latitud, longitud, zoom_acercar) {
      this.map.setView(
        new View({
          center: fromLonLat([longitud,latitud]),
          zoom: zoom_acercar
        })
      );
    }
    render() {
        //let contenido
        //if (this.state.show_datos_punto) {
        //  contenido= <p className="Datos">Hola Joseph</p>;
        //}
        //else{
        //  contenido= <p className="Datos1">Chau Joseph</p>;
        //}

        console.log('-> render App');
        return (
          <div>        
            <div id="mapContainer" ref="mapContainer"></div>
            
            
            <div className="blue-circle" id="popup" title="Características del ensayo seleccionado">
              <div id="popup2"></div>
              <button>X</button>
            </div>
            
            <OverlayTrigger
              className="overlay-probe-layers"
              trigger="click"
              placement="right"
              overlay={
                <Popover id="popover-basic"  className="popover">
                  <Popover.Title className="titulo-popup" as="h3">Capas de Ensayos</Popover.Title>
                  <Popover.Content className="docu overflow-auto">
                  <div class="table-responsive table-sm table-hover table-striped text-nowrap">
                    <table class="table">
                      <tbody>
                        <tr><td><label><button type="checkbox" onClick={this.Check}/>MASW <small>{this.state.estado_capa_masw}</small> <small className={this.state.estado_capa_masw}>ml</small></label></td></tr>
                        <tr><td><label><button type="checkbox" onClick={this.Check1}/>MAM <small>{this.state.estado_capa_mam}</small> <small className={this.state.estado_capa_mam}>ml</small></label></td></tr>
                        <tr><td><label><button type="checkbox" onClick={this.Check2}/>Microtremor <small>{this.state.estado_capa_microtremor}</small> <small className={this.state.estado_capa_microtremor}>ml</small></label></td></tr>
                        <tr><td><label><button type="checkbox" onClick={this.Check3}/>Perforación <small>{this.state.estado_capa_perforacion}</small> <small className={this.state.estado_capa_perforacion}>ml</small></label></td></tr>
                        <tr className={this.state.boton_geoposition}><td><label><button type="checkbox" onClick={this.Check_boton_geoposition}/>Geoposicionamiento <small>{this.state.estado_boton_geoposition}</small> <small className={this.state.estado_boton_geoposition}>ml</small></label></td></tr>
                      </tbody>
                    </table>

                  </div>
                  </Popover.Content>
                </Popover>
              }
            >
              <Button className="capas" title="Capas de Ensayos Geofísicos"></Button>
            </OverlayTrigger>
            <OverlayTrigger
              className="overlay-maps-type"
              trigger="click"
              placement="right"
              overlay={
                <Popover id="popover-basic"  className="popover2">
                  <Popover.Title className="titulo-popup2" as="h3">Tipos de Mapas</Popover.Title>
                  <Popover.Content className="docu2 overflow-auto">
                  <p className="maps_type">
                    <button onClick={this.toggleShowBM} title="Mapa Satelital" className="boton_satelital">Satelital</button>
                    <button onClick={this.toggleShowBMR} title="Mapa Vial" className="boton_road">Vial</button>
                    <button onClick={this.toggleShowWC} title="Mapa Fondo de Agua" className="boton_wc">Agua</button>
                    <button onClick={this.toggleShowOSM} title="Mapa Básico" className="boton_osm">OSM</button>
                    <button onClick={this.toggleShowByN} title="Mapa de Fondo Blanco y Negro" className="boton_byn">Binario</button>
                    <button onClick={this.toggleShowTerreno} title="Mapa Vista de Terreno" className="boton_terreno">Terreno</button>
                  </p>
                  </Popover.Content>
                </Popover>
              }
            >
              <Button title="Tipos de Mapas" className="type_maps" ></Button>
            </OverlayTrigger>
            <div className={this.state.p_control}>
              <button title="Geoposicionamiento" onClick={this.GeoPositionInMap} className="posicionamiento"></button>
              <button title="Extender mapa" onClick={this.centrar_mapa} className="centrado_mapa"></button>
              <OverlayTrigger
              className="overlay-legend"
              trigger="click"
              placement="left"
              overlay={
                <Popover id="popover-basic"  className="popover">
                  <Popover.Title className="titulo-popup" as="h3">Leyenda</Popover.Title>
                  <Popover.Content className="docu overflow-auto">
                  <div class="table-responsive table-sm table-hover table-striped text-nowrap">
                    <table class="table">
                      <thead className="black white-text">
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Nombre</th>
                          <th scope="col">Símbolo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>MASW</td>
                          <td><span className="simbolo_leyenda_t_masw">Ms</span></td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>MAM</td>
                          <td><span className="simbolo_leyenda_t_mam">Ms</span></td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Microtremor</td>
                          <td><span className="simbolo_leyenda_t_microtremor">Ms</span></td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Perforación</td>
                          <td><span className="simbolo_leyenda_t_perforacion">Ms</span></td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                  </Popover.Content>
                </Popover>
              }
            >
              <Button className="leyend" onClick={this.panel} title="Leyenda"></Button>
            </OverlayTrigger>
              <button title="Filtrar Ensayos" onClick={this.filtrar_punto} className="filtrar_puntos"></button>
              <button title="Restablecer filtros" onClick={this.reset_filtros} className="reset_filtro_externo"></button>
              <button title="Activar / Desactivar capa de límites departamental" onClick={this.Check4} className="reset_filtro_externo_movil"><BsLayersHalf className="color-icon-movil-controls"/></button>
              <button title="Activar / Desactivar capa de límites provincial" onClick={this.Check5} className="reset_filtro_externo_movil"><BsFillLayersFill className="color-icon-movil-controls"/></button>
              <button title="Activar / Desactivar capa de límites distrital" onClick={this.Check6} className="reset_filtro_externo_movil"><FiLayers className="color-icon-movil-controls"/></button>
            </div>
            {/* <button title="Tipos de Mapas" onClick={this.toggle('_ini')} className="type_maps" ></button> */}
            <MDBModal color="primary" isOpen={this.state.modal_ini} toggle={this.toggle('_ini')} className="modal-controles" backdrop={true} >
                {/* <MDBModalHeader color="primary" toggle={this.toggle('_ini')}>Controles</MDBModalHeader> */}
                <MDBModalBody className="docu" >
                  {/* <h5>Barra de Controles</h5>
                  <p>Tipos de Mapas</p> */}
                  <p className="maps_type">
                    <button onClick={this.toggleShowBM} className="boton_satelital">Satelital</button>
                    <button onClick={this.toggleShowWC} className="boton_wc">WaterColor</button>
                    <button onClick={this.toggleShowOSM} className="boton_osm">OSM</button>
                    <button onClick={this.toggleShowByN} className="boton_byn">Toner</button>
                    <button onClick={this.toggleShowTerreno} className="boton_terreno">Terreno</button>
                  </p>
                </MDBModalBody>
              </MDBModal>
              <form onSubmit={this.busca_direccion} className="form-busca-direccion_movil" title="Escribe tu dirección para encontrarte">
                <label className="label-busca-direccion_movil" title="Escribe tu dirección para encontrarte">
                  <input  className="input-busca-direccion_movil" type="text" value={this.state.value} onChange={this.cambia_direccion} placeholder="Ingresa tu direción.." title="Escribe tu dirección para encontrarte"/>
                  <FaSearchLocation className="icon-busca-direccion_movil" onClick={this.busca_direccion} title="Escribe tu dirección para encontrarte"/>
                </label>
              </form>
                
            <div className={this.state.caracteristicas_puntos}>
            <div className="encabezado_caracteristicas_movil">
                <span>Ensayo: </span>{this.state.tipo_ensayo} 
                <button title="Cerrar ventana de datos" className="cerrar_caracteristicas" onClick={this.cerrar_caracteristicas}/>
              </div>
              <div className="contenido_caracteristicas">
                <div className="Control_Detalle_Ensayo">
                  <button value="1" onClick={this.cambiaDetalle} className={this.state.control_detalle_boton1}>Detalles</button>
                  <button value="2" onClick={this.cambiaDetalle} className={this.state.control_detalle_boton2}>Imágenes</button>
                  <button value="3" onClick={this.cambiaDetalle} className={this.state.control_detalle_boton3}>Comentario</button>
                </div>
                <div className={this.state.Contenido_Detalle_Ensayo_Movil}>
                  <table>
                    <tbody>
                      <tr><td><strong>Nombre: </strong></td><td>{this.state.nombre_ensayo}</td></tr>
                      <tr><td><strong>Departamento: </strong></td><td> {this.state.departamento_ensayo}</td></tr>
                      <tr><td><strong>Provincia: </strong></td><td> {this.state.provincia_ensayo}</td></tr>
                      <tr><td><strong>Distrito: </strong></td><td> {this.state.distrito_ensayo}</td></tr>
                      <tr><td><strong>Zona: </strong></td><td> {this.state.zona_ensayo}</td></tr>
                      <tr><td><strong>Coordenadas (UTM): </strong></td><td> {this.state.coordUTM1_ensayo}	{this.state.coordUTM2_ensayo}</td></tr>
                      <tr><td><strong>Fuente: </strong></td><td> {this.state.fuente_ensayo}</td></tr>
                      <tr><td><strong>Periodo (s): </strong></td><td> {this.state.periodo_ensayo}</td></tr>
                      <tr><td><strong>V<sub>s 30</sub> (m/s):</strong></td><td> {this.state.velcorte_ensayo}</td></tr>
                      <tr><td><strong>Simbolo: </strong></td><td><span className={this.state.simbolo_ensayo}>Ms</span></td></tr>
                    </tbody>
                  </table>

                </div>
                <div className={this.state.Contenido_Imagenes_Ensayo_Movil}>
                  <BanerDetailMovil ruta_img_1={this.state.img1_ensayo} ruta_img_2={this.state.img2_ensayo} ruta_img_3={this.state.img3_ensayo}/>
                  {/*<a href="./logo192.png" target='_blank' ><img
                    className=""
                    src="./logo192.png" alt="logo" /></a> */}
                </div>
                <div className={this.state.Contenido_Documentacion_Ensayo_Movil}>
                  <p className="comentario">{this.state.documentacion_ensayo}</p>
                </div>
              </div>
              <div className="footer_caracteristicas">
                <button title="Acercarse para ver el punto" className="acercarse_caracteristicas" onClick={this.hacer_zoom}/>
                <button title="Centrar Mapa" className="centrar_caracteristicas" onClick={this.centrar_mapa}/>
                
              </div>
            </div>

            {/*<div className={this.state.panel_filtros}>*/}
            <div className={this.state.panel_filtros}>
              <div className="encabezado_filtros">
                <span>Filtrar Ensayos: </span>
                <button title="Cerrar ventana de filtros" className="cerrar_filtros" onClick={this.cerrar_filtros}/>
                <button title="Resetear filtros usados" className="reset_filtro" onClick={this.reset_filtros}/>
              </div>
              <div className="contenido_filtros">
              <form onSubmit={this.cuando_filtra}>
                    <label className="tipo_filtro_web">
                      <span className="filtrar_por">Filtrar por: </span>
                      <select value={this.state.tipo_filtro} onChange={this.cuando_cambia} className="select_box_web">
                        <option value="none">...</option>
                        <option value="departamento">Departamento</option>
                        <option value="provincia">Provincias</option>
                        <option value="distrito">Distritos</option>
                        <option value="vs">Vs</option>
                        <option value="periodo">Periodo</option>
                        <option value="area">Área</option>
                      </select>
                    </label>
                    <br/>
                    <label className={this.state.tipo_departamento}>
                      <span>Seleccione : </span>
                      <select value={this.state.departamento} onChange={this.cuando_cambia_departamento} className="select_box_web">
                        <option value="Amazonas">Amazonas</option><option value="Ancash">Áncash</option><option value="Apurimac">Apurímac</option>
                        <option value="Arequipa">Arequipa</option><option value="Ayacucho">Ayacucho</option><option value="Cajamarca">Cajamarca</option>
                        <option value="Callao">Callao</option><option value="Cusco">Cusco</option><option value="Huancavelica">Huancavelica</option>
                        <option value="Huanuco">Huánuco</option><option value="Ica">Ica</option><option value="Junin">Junin</option>
                        <option value="La Libertad">La Libertad</option><option value="Lambayeque">Lambayeque</option><option value="Lima">Lima</option>
                        <option value="Loreto">Loreto</option><option value="Madre de Dios">Madre de Dios</option><option value="Moquegua">Moquegua</option>
                        <option value="Pasco">Pasco</option><option value="Piura">Piura</option><option value="Puno">Puno</option>
                        <option value="San Martin">San Martín</option><option value="Tacna">Tacna</option><option value="Tumbes">Tumbes</option>
                        <option value="Ucayali">Ucayali</option>
                      </select>
                    </label>
                    {/*Filtro por provincia*/}
                    <label className={this.state.tipo_provincia}>
                      <span>Seleccione Provincia: </span>
                      <select value={this.state.provincia} onChange={this.cuando_cambia_provincia} className="select_box_web">
                        <option selected value="Lima">Lima (Dep.Lima)</option>
                        {this.contenido_filtros_provincias()}
                        {/* <optgroup label="Dep. Amazónas"><option value="Chachapoyas">Chachapoyas</option></optgroup>
                        <optgroup label="Dep. Arequipa"><option value="Arequipa">Arequipa</option><option value="Islay">Islay</option></optgroup>
                        <optgroup label="Dep. Cajamarca"><option value="Jaen">Jaén</option></optgroup>
                        <optgroup label="Dep. Cusco"><option value="Cusco">Cusco</option></optgroup>
                        <optgroup label="Dep. Huancavelica"><option value="Huaytara">Huaytará</option></optgroup>
                        <optgroup label="Dep. Ica"><option value="Ica">Ica</option><option value="Pisco">Pisco</option></optgroup>
                        <optgroup label="Dep. La Libertad"><option value="Trujillo">Trujillo</option><option value="Ascope">Ascope</option></optgroup>
                        <optgroup label="Dep. Lambayeque"><option value="Lambayeque">Lambayeque</option></optgroup>
                        <optgroup label="Dep. Lima"><option value="Constitucional del Callao">Constitucional del Callao</option><option value="Lima">Lima</option><option value="Canete">Cañete</option></optgroup>
                        <optgroup label="Dep. Loreto"><option value="Alto Amazonas">Alto Amazonas</option></optgroup>
                        <optgroup label="Dep. Piura"><option value="Piura">Piura</option></optgroup>
                        <optgroup label="Dep. San Martín"><option value="San Martin">San Martín</option><option value="Moyobamba">Moyobamba</option></optgroup>
                        <optgroup label="Dep. Tacna"><option value="Tacna">Tacna</option></optgroup> */}
                      </select>
                    </label>
                    {/*Filtro por distrito*/}
                    <label className={this.state.tipo_distrito}>
                      <span>Seleccione Distrito: </span>
                      <select value={this.state.distrito} onChange={this.cuando_cambia_distrito} className="select_box_web">
                        <option selected value="Ancón">Ancón (Dep.Lima)</option>
                        {this.contenido_filtros()}
                        {/* <optgroup label="Dep. Amazónas"><option value="Chachapoyas">Chachapoyas</option></optgroup>
                        <optgroup label="Dep. Arequipa"><option value="Yanahuara">Yanahuara</option><option value="Mollendo">Mollendo</option><option value="Alto Selva Alegre">Alto Selva Alegre</option></optgroup>
                        <optgroup label="Dep. Cajamarca"><option value="Jaen">Jaén</option></optgroup>
                        <optgroup label="Dep. Cusco"><option value="Cusco">Cusco</option><option value="San Jeronimo">San Jerónimo</option></optgroup>
                        <optgroup label="Dep. Huancavelica"><option value="Huaytara">Huaytará</option></optgroup>
                        <optgroup label="Dep. Ica"><option value="Ica">Ica</option><option value="Pisco">Pisco</option><option value="San Clemente">San Clemente</option></optgroup>
                        <optgroup label="Dep. La Libertad"><option value="El Porvenir">El Porvenir</option><option value="Trujillo">Trujillo</option><option value="Victor Larco Herrera">Victor Larco Herrera</option><option value="Ascope">Ascope</option></optgroup>
                        <optgroup label="Dep. Lambayeque"><option value="Lambayeque">Lambayeque</option></optgroup>
                        <optgroup label="Dep. Lima"><option value="Constitucional del Callao">Constitucional del Callao</option>
                          <option value="Ancon">Ancon</option>
                          <option value="Ate">Ate</option>
                          <option value="Barranco">Barranco</option>
                          <option value="Lima">Lima</option>
                          <option value="Chorrillos">Chorrillos</option>
                          <option value="El Agustino">El Agustino</option>
                          <option value="Independencia">Independencia</option>
                          <option value="La Molina">La Molina</option>
                          <option value="Lince">Lince</option>
                          <option value="Los Olivos">Los Olivos</option>
                          <option value="Lurin">Lurín</option>
                          <option value="Magdalena del Mar">Magdalena del Mar</option>
                          <option value="Puente Piedra">Puente Piedra</option>
                          <option value="Rimac">Rimac</option>
                          <option value="San Borja">San Borja</option>
                          <option value="San Juan de Lurigancho">San Juan de Lurigancho</option>
                          <option value="San Juan de Miraflores">San Juan de Miraflores</option>
                          <option value="Villa el Salvador">Villa el Salvador</option>
                          <option value="Bellavista">Bellavista</option>
                          <option value="Chaclacayo">Chaclacayo</option>
                          <option value="Comas">Comas</option>
                          <option value="Pueblo Libre">Pueblo Libre</option>
                          <option value="Lurigancho">Lurigancho</option>
                          <option value="San Luis">San Luis</option>
                          <option value="San Martin de Porres">San Martín de Porres</option>
                          <option value="Santa Anita">Santa Anita</option>
                          <option value="Santiago de Surco">Santiago de Surco</option>
                          <option value="Surquillo">Surquillo</option>
                          <option value="Callao">Callao</option>
                          <option value="Carabayllo">Carabayllo</option>
                          <option value="Villa Maria del Triunfo">Villa Maria del Triunfo</option>
                          <option value="Pachacamac">Pachacamac</option>
                          <option value="Cieneguilla">Cieneguilla</option>
                          <option value="San Isidro">San Isidro</option>
                          <option value="San Bartolo">San Bartolo</option>
                          <option value="La Perla">La Perla</option>
                          <option value="Santa Rosa">Santa Rosa</option>
                          <option value="Ventanilla">Ventanilla</option>
                          <option value="Miraflores">Miraflores</option>
                          <option value="La Victoria">La Victoria</option>
                          <option value="Chilca">Chilca</option>
                          <option value="San Antonio">San Antonio</option>
                          <option value="San Miguel">San Miguel</option>
                          <option value="Pucusana">Pucusana</option>
                        </optgroup>
                        <optgroup label="Dep. Loreto"><option value="Yurimaguas">Yurimaguas</option></optgroup>
                        <optgroup label="Dep. Piura"><option value="Castilla">Castilla</option></optgroup>
                        <optgroup label="Dep. San Martín"><option value="Tarapoto">Tarapoto</option><option value="Moyobamba">Moyobamba</option></optgroup>
                        <optgroup label="Dep. Tacna"><option value="Tacna">Tacna</option></optgroup> */}
                      </select>
                    </label>
                    {/*Filtro por VS*/}
                    <label className={this.state.tipo_vs}>
                      <span>Seleccione Rango Vs: </span>
                      <select value={this.state.vs} onChange={this.cuando_cambia_vs} className="select_box_web">
                        <option value="0-200"> menor a 200 </option>
                        <option value="200-350"> 200-350 </option>
                        <option value="350-550"> 350-550 </option>
                        <option value="550-800"> 550-800 </option>
                        <option value="800-2000"> mayor a 800 </option>
                      </select>
                    </label>
                    {/*Filtro por Periodo*/}
                    <label className={this.state.tipo_periodo}>
                      <span>Seleccione Rango Periodo: </span>
                      <select value={this.state.periodo} onChange={this.cuando_cambia_periodo} className="select_box_web">
                        <option value="0-0.1"> menor a 0.1 seg </option>
                        <option value="0.1-0.25"> 0.1 - 0.25 seg </option>
                        <option value="0.25-0.4"> 0.25 - 0.4 seg </option>
                        <option value="0.40-10"> mayor a 0.40 seg </option>
                      </select>
                    </label>
                    {/*Filtro por Radio*/}
                    <label className={this.state.tipo_radio}>
                      <span>Busca Dirección o Click en el Mapa: </span><br/>
                      <label className="label_coor">Latitud: <input className="coordenadas" onChange={this.cambia_lat_filter} value={this.state.lat_filter} type="text" ></input></label>
                      <label className="label_coor">Longitud: <input  className="coordenadas" onChange={this.cambia_lon_filter} value={this.state.lon_filter} type="text"></input></label>
                      <span>Selecciona Radio</span>
                      <select value={this.state.radio} onChange={this.cuando_cambia_radio} className="select_box_web">
                        <option value="1"> 1 Km </option>
                        <option value="2"> 2 Km </option>
                        <option value="5"> 5 Km </option>
                        <option value="10"> 10 Km </option>
                        <option value="25"> 25 Km </option>
                      </select>
                    </label>

                    <button className="boton_filtro_web" type="submit">Filtrar</button>
                    
                    </form>
                    
              </div>
            </div>

          </div>
        );
    }
}

// module.exports = Map;